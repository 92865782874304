import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const uploadPDFBillsSharedApi = async ({ files, idToken }) => {
  try {
    // Extract filenames from the files array
    const filenames = Object.values(files).map(file => file.name);
    
    // Request signed URLs from the server
    const response = await axios.post(
      `${expressHost}/pdfBills/getSignedUrls`,
      { filenames, detailed: true },
      {
        headers: {
          "Content-Type": "application/json",
          formId: idToken,
        },
      }
    );

    const signedUrls = response.data.data.signedUrls;
    console.log(signedUrls)

    // Upload each file to its corresponding signed URL
    const uploadPromises = Object.entries(signedUrls).map(([filename, url]) => {
      const file = Object.values(files).find(f => f.name === filename);
      console.log(url)
      return axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    console.log('All files uploaded successfully.');
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error; // Rethrow the error for further handling if needed
  }
};

export const uploadPDFBillsShared = async ({ files, idToken, customer }) => {
  try {
    // Extract filenames from the files array
    const filenames = Object.values(files).map(file => file.name);
    
    // Request signed URLs from the server
    const response = await axios.post(
      `${expressHost}/pdfBills/getSignedUrls`,
      { filenames, customer, detailed: true },
      {
        headers: {
          "Content-Type": "application/json",
          uid: idToken,
        },
      }
    );

    const signedUrls = response.data.data.signedUrls;
    console.log(signedUrls)

    // Upload each file to its corresponding signed URL
    const uploadPromises = Object.entries(signedUrls).map(([filename, url]) => {
      const file = Object.values(files).find(f => f.name === filename);
      console.log(url)
      return axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    console.log('All files uploaded successfully.');
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error; // Rethrow the error for further handling if needed
  }
};
