import React from 'react';
import { RedocStandalone } from 'redoc';
import yaml from 'js-yaml';
import fetch from 'isomorphic-fetch';

class Documentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spec: null,
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch('/upwardApi.yaml');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const yamlText = await response.text();
      const spec = yaml.load(yamlText);
      this.setState({ spec, loading: false });
    } catch (error) {
      this.setState({ error: error.toString(), loading: false });
    }
  }

  render() {
    const { spec, loading, error } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    if (error) {
      return <div>Error: {error}</div>;
    }
    return <RedocStandalone spec={spec} />;
  }
}

export default Documentation;