import React, { useEffect, useState } from "react";
import {
  HeaderSubtitle,
  HeaderTitle,
  TextFieldButtonContainer,
  TextFieldContainer,
} from "./styles";
import { TextField } from "@mui/material";
import { SquareButton } from "../../../../components//buttons/SquareButton";
import { setWebhookEndpoint } from "../../apiCalls/setWebhookEndpoint";
import { useGlobalData } from "../../../../store/GlobalDataProvider";
import { useAuth } from "../../../../store/AuthContext";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../../../components/snackbars/MessageBar";

const WebHook = () => {
  const { currentUser } = useAuth();
  const { userData } = useGlobalData();
  const [webhook, setWebhook] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleWebhookChange = (e) => {
    setWebhook(e);
  };

  useEffect(() => {
    if (webhook === "" && userData) {
      setWebhook(userData.overview.webhookEndpoint);
    }
  }, [userData, webhook]);

  
  const handleSave = async () => {
    try {
      await setWebhookEndpoint(await currentUser.getIdToken(), webhook);
      enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
    }
  }

  return (
    <>
      <div style={{ marginTop: "40px", marginBottom: "16px" }}>
        <HeaderTitle>Webhook Endpoint</HeaderTitle>
        <HeaderSubtitle>
          Set the endpoint to receive http requests once energy bill data
          extraction is complete.
        </HeaderSubtitle>
      </div>
      <TextFieldButtonContainer>
        <TextFieldContainer>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={webhook}
            onChange={(e) => {
              handleWebhookChange(e.target.value);
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: 34,
              },
              "& .MuiOutlinedInput-input": {
                padding: '8.5px 14px',
                fontSize: '14px'
              },
              "& .MuiInputLabel-root": {
                transform: 'translate(12px, 7px) scale(1)', // Adjust the label position
                fontSize: '14px'
              },
              "& .MuiInputLabel-shrink": {
                transform: 'translate(14px, -9px) scale(0.85)', // Adjust the label position when shrunk
              },
            }}
          />
        </TextFieldContainer>
        <div
          onClick={handleSave}
        >
          <SquareButton text="Save" width="140px" />
        </div>
      </TextFieldButtonContainer>
    </>
  );
};

export default WebHook;
