import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HeaderSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 8px;
  width: 132px;
`;

export const TableContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const EmptyTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const EmptyTableText = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  height: 75px;
`;

export const EmptyTableButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DeleteContainer = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 3px;
  cursor: pointer;

  transition: 0.3s;
  &:hover {
    background-color: #eff2ff;
    box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
      0px 6px 12px 0px rgba(4, 4, 52, 0.02),
      0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  }
`;

export const DeleteIcon = styled.img`
  margin-left: 6px;
  margin-top: 4px;
`;

export const TextFieldButtonContainer = styled.div`
  width: 500px;
  display: flex;
  gap: 8px;
`

export const TextFieldContainer = styled.div`
  width: 400px;
  height: 33px
`