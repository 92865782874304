import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 3px 8px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.color};
  display: flex;
  gap: 5px;
  background-color: ${(props) => props.background};
  border-radius: 5px;
  box-sizing: border-box;
  width: max-content;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "inherit")};
  height: 24px;
`;

const Icon = styled.img`
  width: 12px;
  height: 12px;
`;

const Pill = ({ color, text, icon, background, margin }) => {
  return (
    <Container color={color} background={background} margin={margin}>
      {text}
      <Icon src={icon} />
    </Container>
  );
};

export default Pill;
