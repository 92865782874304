import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const signUp = async (name, company, email, password) => {
  try {
    const data = await axios({
      method: "post",
      url: `${expressHost}/auth/createNewUserAndCompany`,
      data: {
        name: name,
        company: company,
        email: email,
        password: password
      },
    });
    return data;
  } catch (error) {
    console.log(error)
  }
};