import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: max-content;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;

const Status = styled.div`
  width: 140px;
  height: 140px;
  padding: 30px;
  padding-top: 40px;
  border-radius: 100px;
  background-color: rgba(57, 88, 255, 0.9);
  color: white;
  font-size: 45px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const Text = styled.div`
  font-size: 20px;
  margin-top: 40px;
`;

const ErrorRoute = () => {
  return (
    <Container>
      <Status>404</Status>
      <Text>Oops! This page doesn't exist.</Text>
    </Container>
  );
};

export default ErrorRoute;
