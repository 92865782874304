import React, { useState } from "react";
import { Container, Eye, Text } from "./styles";
import EyeOpen from "../../../../assets/EyeOpen.svg";
import EyeClosed from "../../../../assets/EyeClosed.svg";
import CopyIcon from "../../../../assets/CopyIcon.svg";
import { copyToClipboard } from "../../../../utils/copyToClipboard";
import TrashGrey from "../../../../assets/TrashGrey.svg";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../../../components/snackbars/MessageBar";

const HideAndCopy = ({
  text,
  withDelete = false,
  deleteFunction = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const handleClickShow = () => {
    setShow(!show);
  };
  let split = text.split("");
  let bullet = "\u2022";
  let hiddenPass = "";
  for (let i = 0; i < split.length; i++) {
    hiddenPass = hiddenPass + bullet;
  }
  return (
    <Container>
      {show && (
        <>
          <Eye src={EyeClosed} onClick={handleClickShow} title="Hide" />
          <Text>{text}</Text>
        </>
      )}
      {!show && (
        <>
          <Eye src={EyeOpen} onClick={handleClickShow} title="Show" />
          <Text>{hiddenPass}</Text>
        </>
      )}
      <Eye
        src={CopyIcon}
        onClick={() => {
          try {
            copyToClipboard(text);
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
          } catch (e) {
            console.log("error", e);
          }
        }}
        title="Copy"
      />
      {withDelete && (
        <Eye title="Delete" src={TrashGrey} onClick={deleteFunction} />
      )}
    </Container>
  );
};

export default HideAndCopy;
