import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingState = () => {
    return (
        <>
            <CircularProgress 
                size={40}
                style={{ color: "#3958FF", marginTop: 120, marginLeft: 430}}
            />
        </>
    );
};

export default LoadingState;