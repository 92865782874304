import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import { SquareButton } from "../../../components/buttons/SquareButton";
import { deleteDataRequest } from "../apiCalls/deleteDataRequest";
import { useAuth } from "../../../store/AuthContext";

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessText = styled.div`
  margin-top: -16px;
  font-size: 26px;
  font-weight: 500;
`;

const Text = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 26px;
`;

const DeleteModal = ({ open, handleClose, deleteKey, status }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const modalContent = () => {
    return (
      <Container>
        <SuccessText>Delete Data</SuccessText>
        <Text>
          Are you sure you want to delete this data? This action is
          irreversable.
        </Text>

        <div
          onClick={async () => {
            setLoading(true)
            try {
              await deleteDataRequest(deleteKey, status, await currentUser.getIdToken());
              handleClose();
            } catch(e) {
              console.log(e)
            } finally {
              setLoading(false)
            }
          }}
        >
          <SquareButton
            text="Delete"
            bordercolor={"#ED3939"}
            backgroundcolor={"#FDECEC"}
            hovercolor={"#FAC7C7"}
            isloading={loading}
            loadingcolor={"#ED3939"}
          />
        </div>
      </Container>
    );
  };
  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default DeleteModal;
