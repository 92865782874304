import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const getCompanyInfo = async (idToken) => {
  try {
    const data = await axios({
      method: "get",
      url: `${expressHost}/auth/getCompanyInfoEncrypted`,
      headers: { uid: idToken },
    });
    return data.data;
  } catch (error) {
    console.log(error)
  }
};
