import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "#f3f6ff"};
  border: 1px solid;
  border-color: ${({bordercolor}) =>
    bordercolor ? bordercolor : "#3958ff"};
  border-radius: 5px;
  padding: 8px 0px 8px 0px;
  display: inline-block;
  text-align: center;
  width: ${(props) => (props.width ? props.width : "160px")};
  cursor: ${(props) => (props.isloading || props.disabled ? "auto" : "pointer")};
  transition: 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.isloading
        ? props.backgroundcolor
          ? props.backgroundcolor
          : "#f3f6ff"
        : props.hovercolor
        ? props.hovercolor
        : "#CAD7FF"};
  }
`;

const ButtonText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({bordercolor}) => (bordercolor ? bordercolor : "#3958ff")};
  transition: 0.3s;
  cursor: ${(props) => (props.isloading || props.disabled ? "auto" : "pointer")};
  width: 100%;
  text-align: center;
`;

export const SquareButton = ({
  text,
  isloading = false,
  bordercolor = null,
  backgroundcolor = null,
  hovercolor = null,
  width = null,
  disabled = false,
  loadingcolor = "#3958ff"
}) => {
  return (
    <ButtonContainer
      isloading={isloading}
      bordercolor={disabled ? "#808080" : bordercolor}
      backgroundcolor={disabled ? "#f2f2f2" : backgroundcolor}
      hovercolor={disabled ? "#f2f2f2" : hovercolor}
      width={width}
      disabled={disabled}
    >
      {isloading && <div style={{ marginTop: -1, marginBottom: -3,}}><CircularProgress size={14} style={{ color: loadingcolor }} /></div>}
      {!isloading && <ButtonText disabled={disabled} bordercolor={disabled ? "#808080" : bordercolor}>{text}</ButtonText>}
    </ButtonContainer>
  );
};
