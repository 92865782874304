import React from "react";
import {
  EmptyTableContainer,
  EmptyTableText,
} from "./styles";

const EmptyTableState = ({ handleClick }) => {
  return (
    <EmptyTableContainer>
      <EmptyTableText>No API keys yet</EmptyTableText>
    </EmptyTableContainer>
  );
};

export default EmptyTableState;
