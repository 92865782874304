import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { dark_blue, blue } from "../../utils/colors";

const ButtonContainer = styled.div`
  padding: 16px 3px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => (props.disabled ? "rgba(0,0,0,0.2)" : blue)};
  border-radius: 5px;
  // margin: 0 auto;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "rbga(0,0,0,0.2)" : dark_blue};
    transition-duration: 1s;
  }
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const ButtonContent = styled.div`
  color: white;
  width: max-content;
  font-size: 12px;
  font-weight: 500;
  margin: 0 auto;
  // margin-top: 12px;
  line-height: 21px;
`;

const FormButton = ({
  handleClick = () => {},
  content = "",
  isloading = false,
  height = "41px",
  width = "235px",
  disabled = false,
}) => {
  return (
    <ButtonContainer
      onClick={() => { if (!disabled) {handleClick()}}}
      height={height}
      width={width}
      disabled={disabled}
    >
      <ButtonContent>
        {isloading && (
          <CircularProgress
            size={18}
            style={{ color: "white", marginTop: 5 }}
          />
        )}
        {!isloading && <>{content}</>}
      </ButtonContent>
    </ButtonContainer>
  );
};

export default FormButton;
