import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const deleteAPIKey = async (key, idToken) => {
  try {
    const data = await axios({
      method: "POST",
      url: `${expressHost}/apiAdmin/deleteKey`,
      data: {
        key: key,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (e) {

  }
};
