import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment'; // Ensure you have moment installed
import { isNil } from 'lodash';

const formatSeriesData = (rawData, serviceClass) => {

    // filter for correct service class
    const filteredData = rawData.filter((val) => {return val.serviceClass === serviceClass})

    // Ensure the data is sorted chronologically by 'month'
    const sortedData = filteredData.sort((a, b) => moment(a.month).diff(moment(b.month)));
    
    if (sortedData.length === 0) return { categories: [], series: [] };

    // Get the earliest and latest month
    const startMonth = moment(sortedData[0].month);
    const endMonth = moment(sortedData[sortedData.length - 1].month);

    // Generate a complete list of months in range
    const monthsRange = [];
    let currentMonth = startMonth.clone();

    while (currentMonth <= endMonth) {
        monthsRange.push(currentMonth.format('YYYY-MM'));
        currentMonth.add(1, 'months');
    }

    // Group data by accountNumber and fill in gaps
    const seriesData = {};
    sortedData.forEach(data => {
        const { accountNumber, month, usage } = data;
        if (!seriesData[accountNumber]) {
            seriesData[accountNumber] = { name: accountNumber, data: Array(monthsRange.length).fill(0) };
        }
        const monthIndex = monthsRange.indexOf(month);
        if (monthIndex !== -1) {
            seriesData[accountNumber].data[monthIndex] = usage;
        }
    });

    // Convert object to array
    const formattedSeries = Object.values(seriesData);

    return {
        categories: monthsRange.map(month => moment(month, 'YYYY-MM').format('MMM YY')),
        series: formattedSeries,
    };
};

const BarChart = ({ monthlyData, unit, serviceClass }) => {
    const { categories, series } = formatSeriesData(monthlyData, serviceClass)

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
        },
        grid: {
            borderColor: "rgba(0,0,0,0.05)",
            padding: {
                right: 30 // Add some right padding to ensure the last label is visible
            }
        },
        legend: {
            offsetY: 2,
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            itemMargin: {
                horizontal: 12,
            },
            fontSize: '13px',
            fontFamily: 'Inter',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: 16,
                borderRadius: 8, // Adjust this value to round the corners of the bars
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'all'
            },
        },
        colors: ["#3958ff","#912ED3","#58dbbd","#3a0ca3","#f72585","#d74836","#909090"],
        xaxis: {
            categories: categories,
        },
        yaxis: [
            {
              tickAmount: 3,
              min: 0,
              labels: {
                formatter: function (number) {
                  if (isNil(number)) {
                    return;
                  }
                  return number.toLocaleString("en-US") + ` ${unit}`;
                },
              },
              forceNiceScale: true,
            }
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical', // You can also use 'horizontal'
                shadeIntensity: 0.25,
                gradientToColors: undefined, // Optional: specify colors here
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 100], // Define gradient stop points
            },
        },
    };

    return (
        <div style={{marginTop: 16}}>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="326px"
            />
        </div>
    );
};

export default BarChart;