import React from "react";
import styled from "styled-components";

const NoteContainer = styled.div`
  padding: 16px;
  border: 1px solid ${({bordercolor}) => bordercolor};
  border-radius: 3px;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.fontcolor};
`;

const Note = ({ backgroundcolor, bordercolor, fontcolor, children }) => {
  return (
    <NoteContainer
      bordercolor={bordercolor}
      fontcolor={fontcolor}
      backgroundcolor={backgroundcolor}
    >
      {children}
    </NoteContainer>
  );
};

export default Note;
