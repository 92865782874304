import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const downloadBillsDataEnergyStarFormat = async ({ json, idToken }) => {
  try {
    const download = await axios({
      url: `${expressHost}/download/downloadMeterDataEnergyStar`,
      method: "post",
      headers: { idtoken: idToken },
      responseType: "arraybuffer",
      data: {
        data: json,
      },
    });
    return download;
  } catch (error) {
    console.log(error);
  }
};
