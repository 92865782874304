import React from "react";
import styled from "styled-components";
import Back from "../../assets/Back.svg"
import { blue } from "../../utils/colors";

const Container = styled.div`
    display: flex;
    cursor: pointer;
    transition: .3s;
    width: 36px;

    &:hover {
        color: ${blue}
    }
`

const ButtonText = styled.div`
    font-size: 12px;
    margin-left: 3px;
`;

const Icon = styled.img`
    margin-top: 3px;
    height: 8px
`

const BackButton = ({ action }) => {
  return (
    <Container onClick={() => {action()}}>
        <Icon src={Back}/>
        <ButtonText>Back</ButtonText>
    </Container>
  )
};

export default BackButton;
