import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const updateSolarProductivity = async (idToken, SpArr) => {
  console.log(SpArr)
  try {
    const data = await axios.post(
      `${expressHost}/auth/updateSolarProductivity`,
      { solarProductivity: SpArr },
      {
        headers: {
          "Content-Type": "application/json",
          idToken: idToken,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    throw error
  }
};