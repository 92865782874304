import React, { useState, useEffect} from "react";
import { Container, PageContainer, HeaderTitle } from "./styles";
import Page from "../../components/page/index";
import LoginWrapper from "../../store/LoginWrapper";
import Enable2FA from "./components/Enable2FA";
import VerifyEmail from "./components/VerifyEmail";
import { useAuth } from "../../store/AuthContext";
import Tabs from "../../components/Tabs";
import { SolarSettings } from "./pages/solarProductivity";

const Settings = () => {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const { currentUser } = useAuth();
    const [currentTab, setCurrentTab] = useState(0)
    
    const handleTabChange = (target) => {
      setCurrentTab(target)
    }

    // Check email verification status on component mount
    useEffect(() => {
      if (currentUser) {
        setIsEmailVerified(currentUser.emailVerified);
      }
    }, [currentUser]);

    return (
        <LoginWrapper>
            <Page>
                <PageContainer>
                    <Container width={"900px"}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <HeaderTitle>Settings</HeaderTitle>
                        </div>
                        <div style={{marginTop: 12}}>
                            <Tabs
                                tabs={["Account", "Solar"]}
                                setTabInParent={handleTabChange}
                            />
                        </div>
                        { currentTab === 0 ? 
                          <>
                            <VerifyEmail isEmailVerified={isEmailVerified}/>
                            <Enable2FA isEmailVerified={isEmailVerified}/>
                          </> : <></>
                        }
                        { currentTab === 1 ? 
                          <>
                            <SolarSettings />
                          </> : <></>
                        }
                        
                    </Container>
                </PageContainer>
            </Page>
        </LoginWrapper>
  );
};

export default Settings;
