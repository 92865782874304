import axios from "axios";
import { expressHost } from "../../../../../utils/hosts";

export const pdfBillReader = async ({ files, idToken, customer, detailed }) => {
  try {
    // Extract filenames from the files array
    const filenames = Object.values(files).map(file => file.name);
    
    // Request signed URLs from the server
    const response = await axios.post(
      `${expressHost}/pdfBills/getSignedUrls`,
      { filenames, customer, detailed },
      {
        headers: {
          "Content-Type": "application/json",
          idToken: idToken,
        },
      }
    );

    const signedUrls = response.data.data.signedUrls;

    // Upload each file to its corresponding signed URL
    const uploadPromises = Object.entries(signedUrls).map(([filename, url]) => {
      const file = Object.values(files).find(f => f.name === filename);
      return axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error; // Rethrow the error for further handling if needed
  }
};
