import axios from "axios";
import { expressHost } from "../../utils/hosts";

export const getUserData = async (idToken) => {
  try {
    const data = await axios({
      url: `${expressHost}/auth/getUserData`,
      headers: { idtoken: idToken },
      validateStatus: () => true // Pass validateStatus option here
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
