import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { blue } from "../utils/colors";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const Icon = styled.img`
  height: 15px;
  width: 15px;
  &:hover {
    filter: ${(props) =>
      !props.active
        ? "brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%) hue-rotate(218deg) brightness(103%) contrast(113%)"
        : "none"};
  }
  filter: ${(props) =>
    props.active
      ? "brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%) hue-rotate(218deg) brightness(103%) contrast(113%)"
      : "none"};
`;

const Tab = styled.div`
  background-color: "transparent";
  color: ${(props) => (props.active ? blue : "#7A7A7A")};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${blue}` : "2px solid transparent"};
  font-weight: 600;
  font-size: 12px;
  padding: 5px 13px;
  display: flex;
  gap: 10px;
  align-items: center;
  &:hover ${Icon} {
    filter: ${(props) =>
      !props.active
        ? "brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%) hue-rotate(218deg) brightness(103%) contrast(113%)"
        : "brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%) hue-rotate(218deg) brightness(103%) contrast(113%)"};
  }
  &:hover {
    color: ${blue};
    border-bottom: 2px solid ${blue};
    cursor: pointer;
  }
`;

const Tabs = ({ tabs, setTabInParent, refresh, tabIcons = [] }) => {
  const [active, setActive] = useState(0);
  const onClickTab = (i) => {
    setActive(i);
    setTabInParent(i);
  };

  useEffect(() => {
    setActive(0);
  }, [refresh]);

  return (
    <Container>
      {tabs.map((tab, i) => {
        return (
          <Tab key={i} active={i === active} onClick={() => onClickTab(i)}>
            {tabIcons.length > 0 && (
              <Icon src={tabIcons[i]} active={i === active} />
            )}
            {tab}
          </Tab>
        );
      })}
    </Container>
  );
};

export default Tabs;
