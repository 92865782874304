import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { black, white } from "../utils/colors";
import { isNil } from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${black};
`;

const Input = ({
  title = null,
  error = false,
  disabled = false,
  value,
  defaultValue = null,
  onChangeErrorFalse = false,
  setChangeInParent = null,
  width = null,
  type = "text",
}) => {
  const [inputValue, setInputValue] = useState(null);

  const [inputError, setInputError] = useState(error);
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const onChangeInput = (e) => {
    if (!isNil(setChangeInParent)) {
      setChangeInParent(e.target.value);
    }
    setInputValue(e.target.value);
    if (onChangeErrorFalse) {
      setInputError(false);
    }
  };
  return (
    <Container>
      {!isNil(title) && <Label>{title}</Label>}

      <FormControl
        varaint="outlined"
        sx={{ width: !isNil(width) ? width : "100%" }}
      >
        <TextField
          value={inputValue}
          type={type}
          onChange={onChangeInput}
          error={inputError}
          disabled={disabled}
          defaultValue={defaultValue}
          sx={{
            backgroundColor: white,
            input: {
              color: inputError ? "red" : black,
              fontSize: "14px",
              fontWeight: inputError ? "600" : "400",
            },
            width: "100%",
          }}
          inputProps={{ height: "20px" }}
          size="small"
        />
      </FormControl>
    </Container>
  );
};

export default Input;
