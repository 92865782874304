import { createTheme } from '@mui/material/styles';

export const dark_blue = "#132FC0";
export const blue = "#3958FF";
export const grey = "#dfdfdf";
export const black = "#000000";
export const white = "#ffffff";
export const light_grey = "#DADADA";
export const grey2 = "#E6E6E6";
export const grey3 = "#9E9E9E";
export const grey4 = "#eaeaea";
export const light_blue = "#F3F6FF";
export const light_orange = '#FFE7D5'
export const dark_orange = "#F25700";
export const light_green = "#dcedc8";
export const light_yellow = "#ffecb3";
export const light_red = "#ffcdd2";
export const dark_red = "#d50000";
export const light_purple = "#f9f9fb";
export const blue_50 = "#eff6ff";
export const dark_green = "#388E3C";
// export const warning_orange = "#E68200";
export const warning_orange = "#E69D00";

export const blueTheme = createTheme({
  palette: {
    primary: {
      main: '#3958FF',
      light: '#f3f6ff',
      dark: '#132FC0',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Inter',
  }
});

export const ToggleTheme = createTheme({
  palette: {
    primary: {
      main: '#3958FF',
      light: '#f3f6ff',
      dark: '#132FC0',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Inter',
    button: {
      textTransform: 'none'
    }
  },
});
