import React from "react";
import styled from "styled-components";
import NavBar from "../../components/navbar/NavBar";
import Card from "../../components/cards/Card";
import { useNavigate } from "react-router-dom";
import blueGradient from "../../assets/blueGradient.png"
import redGradient from "../../assets/redGradient.png"
import arrow from "../../assets/ArrowBlue.svg"
import LoginWrapper from "../../store/LoginWrapper";

const NavContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 900px;
  margin: 60px auto 60px auto;
`;

const PageContainer = styled.div`
  width: Calc(100vw - 220px);
  min-width: 924px;
  background-color: #fdfdfd;
`;

const SectionTitle = styled.div`
  color: white;
  font-size: 36px;
  font-style: normal;
  line-height: normal;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "0px")};
`;

const WhiteSubTitle = styled.div`
  color: white;
  font-size: 24px;
`

const SubTitle = styled.div`
  font-size: 24px;
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 8px;
  margin-top: 24px;
`;

const Section = styled.div`
`;

const OrderedList = styled.ol`
  margin-left: 24px;
  padding-left: 20px;
  list-style-type: decimal; /* This sets the list to be numbered */
  margin-top: 24px;
`;

const ListItem = styled.li`
  font-size: 14px;
  font-weight: 600;
  color: #808080;
  margin-top: 12px;
`;

const WelcomeContainer = styled.div`
  border-radius: 5px;
  background-image: url(${blueGradient});
  background-size: 100% 100%;
  width: 836px;
  padding: 32px;
  padding-top: 132px;
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09), 0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  opacity: .9;
`

const GetStartedContainer = styled.div`
  border-radius: 5px;
  background-image: url(${redGradient});
  background-size: 100% 100%;
  width: 316px;
  padding: 24px;
  height: 297.5px;
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09), 0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
`

const ButtonArrow = styled.img`
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%);
  height: 16px;
  margin-left: 8px;
  margin-top: 8px;
  opacity: 0;
  transition: .3s
`

const ButtonTextContainer = styled.div`
  display: flex;
  padding-top: 48px;
`

const ButtonText = styled.div`
  font-size: 24px;
  color: white;
`

const GetStartedButton = styled.div`
  margin-top: 16px;
  border-radius: 5px;
  border: 1px solid white;
  width: 300px;
  padding: 8px;
  transition: .3s;

  &:hover {
    cursor: pointer;
    background-color: rgb(256, 256, 256, .1);

    & ${ButtonArrow} {
      opacity: 1;
    }

    & ${ButtonText} {
      font-weight: 400
    }
  }
`


const Flex = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 16px;
`

const Home = () => {
  const navigate = useNavigate();
  
  const navigateToUploadBill = () => {
    navigate("/upload-energy-bills");
  };

  const navigateToApi = () => {
    navigate("/api");
  };

  return (
    <LoginWrapper>
      <NavContainer>
        <NavBar currentPage={"home"} />
        <PageContainer>
          <Container>
            <WelcomeContainer>
              <SectionTitle>Welcome to Upward</SectionTitle>
            </WelcomeContainer>
            <Flex>
              <Card
                width={'520px'}
                children={
                  <>
                    <SubTitle>How it Works</SubTitle>
                    <Section>
                      <Paragraph>
                        Upward revolutionizes data extraction from energy and water bills with AI-powered OCR technology. Our platform eliminates data entry, and makes the process fast, accurate, and effortless.
                      </Paragraph>
                      <Paragraph>Drag and drop your bills, send requests to customers, or integrate our API. We extract the data quickly and accurately so you dont have to.</Paragraph>
                    </Section>

                    <OrderedList>
                      <ListItem>Upload bills with a few clicks</ListItem>
                      <ListItem>Review extracted data</ListItem>
                      <ListItem>Share and export into your existing processes</ListItem>
                    </OrderedList>
                  </>
                } 
              />
              <GetStartedContainer>
              <WhiteSubTitle>Get Started</WhiteSubTitle>
              <div style={{height: '44px'}}></div>
              <GetStartedButton onClick={() => {navigateToUploadBill()}}>
                <ButtonTextContainer>
                  <ButtonText>Upload Bills</ButtonText>
                  <ButtonArrow src={arrow} />
                </ButtonTextContainer>
              </GetStartedButton>
              <GetStartedButton onClick={() => {navigateToApi()}}>
                <ButtonTextContainer>
                  <ButtonText>API</ButtonText>
                  <ButtonArrow src={arrow} />
                </ButtonTextContainer>
              </GetStartedButton>
              </GetStartedContainer>
            </Flex>
          </Container>
        </PageContainer>
      </NavContainer>
    </LoginWrapper>
  );
};

export default Home;
