import React from "react";
import styled from "styled-components";
import {
  blue,
  grey2,
  white,
  black,
  grey3,
  grey4,
} from "../../../utils/colors";
import { isNil } from "lodash";

const Container = styled.div`
  display: flex;
  gap: 26px;
  padding: 16px;
  border: 1px solid ${(props) => (props.active ? blue : grey2)};
  background-color: ${(props) =>
    props.active ? "#DBEAFE" : props.disabled ? grey4 : white};
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 3px;
  transition: 0.3s;
  &:hover {
    border: ${(props) =>
      props.disabled ? `1px solid ${grey4}` : `1px solid ${blue}`};
    background-color: ${(props) => (props.disabled ? `${grey4}` : `#DBEAFE`)};
  }
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

const Title = styled.div`
  color: ${(props) => (props.disabled ? grey3 : black)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const Description = styled.div`
  color: ${(props) => (props.disabled ? grey3 : black)};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalCard = ({
  active,
  disabled,
  imgSrc,
  title,
  description,
  onClick,
  subText = null,
}) => {
  return (
    <Container active={active} onClick={onClick} disabled={disabled}>
      <Icon src={imgSrc} />
      <TextContainer>
        <Title disabled={disabled}>{title}</Title>
        <Description disabled={disabled}>{description}</Description>
        {!isNil(subText) && <Description>{subText}</Description>}
      </TextContainer>
    </Container>
  );
};

export default HorizontalCard;
