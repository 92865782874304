import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const deleteDataRequest = async (requestId, status, idToken) => {
  try {
    const data = await axios({
      url: `${expressHost}/dataRequests/deleteDataRequest`,
      method: "POST",
      data: {
        requestId: requestId,
        status: status,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
