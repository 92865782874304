import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const updateRequestBillData = async (billsData, requestId, idToken) => {
  try {
    const data = await axios({
      method: "post",
      url: `${expressHost}/dataRequests/updateRequestBillData`,
      data: {
        data: billsData,
        requestId: requestId,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
