import { DocumentScannerOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { expressHost } from '../../../../utils/hosts';
import { useAuth } from '../../../../store/AuthContext';

const ViewPDF = (props) => {
    const {currentUser } = useAuth()

    const handleDownload = async () => {
        try {
        // Fetch the PDF buffer from the endpoint
        const response = await axios({
            url: `${expressHost}/download/viewBill`,
            method: 'post',
            headers: { idtoken: await currentUser.getIdToken() },
            responseType: 'blob', // Set the response type to 'blob'
            data: {
                filename: props.node.data.location,
            },
        });

        if (response.status !== 200) {
             console.log('Network response was not ok');
        }

        const blob = response.data; // response.data will be the blob

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Open the URL in a new tab
        const newTab = window.open();
        if (newTab) {
            newTab.location.href = url;
        } else {
            alert('Please allow popups for this website');
        }

        } catch (error) {
        console.error('There was an error fetching the PDF:', error);
        }
    };

    return (
        <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
            <IconButton size="small" onClick={handleDownload}>
                <DocumentScannerOutlined style={{ color: '#3958ff' }} fontSize="small" />
            </IconButton>
        </div>
    )
};

export default ViewPDF;