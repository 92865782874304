import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbar = ({ children, ...props }) => {
  return (
    <Scrollbars
        renderThumbVertical={({ style, ...props }) => (
            <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '5px' }} />
        )}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        {...props}
    >
        {children}
    </Scrollbars>
  );
};

export default CustomScrollbar;