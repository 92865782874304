import React, { useState, useEffect} from "react";
import { TabHeaderSubtitle, TabHeaderTitle } from "../styles";
import { useGlobalData } from "../../../store/GlobalDataProvider";
import { defaultSolarProductivity } from "../../../utils/defaultSolarProductivity";
import SolarProductivityBarChart from "../components/solarProductivityBarChart";
import { TextField } from "@mui/material";
import styled from "styled-components";
import { blue } from "../../../utils/colors";
import { useAuth } from "../../../store/AuthContext";
import { updateSolarProductivity } from "../apiCalls/updateSolarProductivity";
import { buttons, snackbarStyles } from "../../../components/snackbars/MessageBar";
import { SquareButton } from "../../../components/buttons/SquareButton";
import { useSnackbar } from "notistack";

const InputContainer = styled.div`
    display: flex;
    margin-top: 16px;
    width: 200px;
    height: 34px;
    justify-content: space-between;
    border-left: 2px solid ${blue};
`

const Label = styled.div`
    margin-left: 8px;
    margin-top: 9px;
    font-size: 14px;
`

function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false; // Optional: Can remove if lengths are guaranteed to be the same
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export const SolarSettings = () => {
    const { userData } = useGlobalData()
    const { currentUser } = useAuth()
    const { enqueueSnackbar } = useSnackbar();
    const [originalSP, setOriginalSP] = useState(userData.userData.solarProductivity ?? defaultSolarProductivity)
    const [currentSP, setCurrentSP] = useState(userData.userData.solarProductivity ?? defaultSolarProductivity)
    const [loading, setLoading] = useState(false)
    const [edited, setEdited] = useState(false)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const updateSP = (e, i) => {
        var newSP = [...currentSP]
        newSP[i] = parseFloat(e)
        setCurrentSP(newSP)
        setEdited(!arraysAreEqual(newSP, originalSP))
    }

    const saveSP = async () => {
        setLoading(true)
        try {
            await updateSolarProductivity(await currentUser.getIdToken(), currentSP)
            setLoading(false)
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
            setEdited(false)
        } catch (error) {
            enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
            console.log(error)
            setLoading(false)
        }  
    }

    return (
        <>
            <div style={{ marginTop: "40px", marginBottom: "16px" }}>
                <TabHeaderTitle>Set Solar Productivity</TabHeaderTitle>
                <TabHeaderSubtitle>Set the relative solar productivity for each month of the year. These values will be used to calculate the Solar Avoided Cost for uploaded electricity bills.</TabHeaderSubtitle>
            </div>

            <SolarProductivityBarChart monthlyData={currentSP} />

            <div style={{marginTop: -24, width: 720, height: 200, display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                {months.map((month, index) => {
                    return (
                        <>
                            <InputContainer>
                                <Label>{month}</Label>
                                <div style={{width: 90}}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={currentSP[index]}
                                        placeholder={month}
                                        onChange={(e) => updateSP(e.target.value, index)}
                                        sx={{
                                        "& .MuiInputBase-root": {
                                            height: 34,
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            padding: '8.5px 14px',
                                            fontSize: '14px'
                                        },
                                        "& .MuiInputLabel-root": {
                                            transform: 'translate(12px, 7px) scale(1)', // Adjust the label position
                                            fontSize: '14px'
                                        },
                                        "& .MuiInputLabel-shrink": {
                                            transform: 'translate(14px, -9px) scale(0.85)', // Adjust the label position when shrunk
                                        },
                                        }}
                                    />
                                </div>
                            </InputContainer>
                        </>
                    )
                })}
            </div>
            <div style={{marginTop: '16px', width: 90 }} onClick={edited ? saveSP : () => {}}>
                <SquareButton width="90px" disabled={!edited} text='Save' isloading={loading} />
            </div>

            
        </>
    )
}