import React from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

const Container = styled(Dialog)`
  display: flex;
  // width: max-content;
  // height: max-content;
  gap: 8px;
  z-index: 3;
  flex-direction: column;
  // box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
  //   0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05) !important;
`;

const DialogContainer = styled.div`
  padding: 30px;
  width: max-content;
`;

const ModalHeader = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 500;
`;

const ModalContent = styled.div``;

const ModalFooter = styled.div``;

const ModalSubHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 500px;
  line-height: 21px;
`;

const Modal = ({
  open = false,
  handleClose = () => {},
  modalHeader = <></>,
  modalFooter = <></>,
  modalContent = <></>,
  modalSubHeader = <></>,
}) => {
  return (
    <Container
      open={open}
      onClose={handleClose}
      componentsProps={{
        backdrop: { style: { backgroundColor: "rgba(265,265,265, 0.75)" } },
      }}
      sx={{
        boxShadow:
          "0px 0px 3px 0px rgba(4, 4, 52, 0.09),0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05)",
      }}
      maxWidth={"md"}
    >
      <DialogContainer>
        <ModalHeader>
          {modalHeader}
          <ModalSubHeader>{modalSubHeader}</ModalSubHeader>
        </ModalHeader>
        <ModalContent>{modalContent}</ModalContent>
        <ModalFooter>{modalFooter}</ModalFooter>
      </DialogContainer>
    </Container>
  );
};

export default Modal;
