import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const downloadEnergyBills = async ({ idToken, files }) => {
  try {
    const data = await axios({
      url: `${expressHost}/download/pdfBills`,
      method: "post",
      data: {
        files: files,
      },
      headers: { idtoken: idToken },
      responseType: "arraybuffer",
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
