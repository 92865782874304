import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { isNil } from "lodash";
import { format } from 'date-fns'; 

function parseDateString(dateString) {
  let date;

  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      // Format YYYY-MM-DD
      const [year, month, day] = dateString.split('-').map(Number);
      date = new Date(year, month - 1, day);
  } else if (dateString.match(/^\d{4}-\d{1,2}$/)) {
      // Format YYYY-MM or YYYY-M
      const [year, month] = dateString.split('-').map(Number);
      date = new Date(year, month - 1);
  }

  return date;
}

function getDate(item) {
  if (item.deliveryDate) {
    return item.deliveryDate
  } else if (item.startDate) {
    return item.startDate
  } else if (item.month) {
    return item.month
  }
}

const sumYValuesFromObjects = (arrayOfDataObjects) => {
  const map = new Map();

  arrayOfDataObjects.forEach(dataObj => {
      dataObj.data.forEach(item => {
          const dateKey = item.x.getTime(); // Using the timestamp as the map key
          if (map.has(dateKey)) {
              map.get(dateKey).y += item.y;
          } else {
              map.set(dateKey, { x: item.x, y: item.y });
          }
      });
  });

  // Convert the map back to an array
  return Array.from(map.values());
};

function convertToChartData(data, serviceClass, monthly, demand) {
  var accountNumbers = []
  var result = []

  for (let item of data) {
    if (item.serviceClass !== serviceClass || !getDate(item)) {
      continue
    }

    if (!accountNumbers.includes(item.accountNumber)){

      result.push((demand && item.demand) ? {
        name: item.accountNumber,
        data:[{
          x: parseDateString(getDate(item)),
          y: item.demand
        }]
      } : {
        name: item.accountNumber,
        data:[{
          x: parseDateString(getDate(item)),
          y: item.usage
        }]
      })

      accountNumbers.push(item.accountNumber)
    } else {
      var index = accountNumbers.indexOf(item.accountNumber)
      result[index].data.push((demand && item.demand) ? {
        x: parseDateString(getDate(item)),
        y: item.demand
      } : {
        x: parseDateString(getDate(item)),
        y: item.usage
      })
    }
  }

  if (monthly & result.length > 1 & !demand) {
    var totals = sumYValuesFromObjects(result)
    result.push({
      name: 'Total Usage',
      data: totals
    })
  }

  var strokeWidth = []

  for (let index in result) {
    var sortedData = result[index].data.sort((a, b) => a.x - b.x);
    result[index].data = sortedData
    strokeWidth.push(result[index].data.length < 2 ? 0 : 2)
  }
  return { chartData: result, strokeWidth: strokeWidth }
}


const UsageChart = ({ serviceClass, unit, preprocessedData, demand }) => {

  const {chartData, strokeWidth} = useMemo(() => {return convertToChartData(preprocessedData, serviceClass, false, false)}, [preprocessedData, serviceClass])
  // const [chartDataDemand, setChartDataDemand] = useState(null)

  // useEffect(() => {
  //   if (serviceClass === 'electric') {
  //     setChartDataDemand(convertToChartData(preprocessedData, serviceClass, false, true));
  //   }
  // }, [serviceClass, preprocessedData])

  const [series, setSeries] = useState(chartData)

  useEffect(() => {
    demand ?
    setSeries(convertToChartData(preprocessedData, serviceClass, false, true).chartData) :
    setSeries(chartData)
  }, [demand, serviceClass, unit, preprocessedData, chartData])

  var options = {
    legend: {
      offsetY: 8,
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      itemMargin: {
        horizontal: 12,
      },
      fontSize: '13px',
      fontFamily: 'Inter',
      onItemClick: {
        toggleDataSeries: false
      },
    },
    tooltip: {
      x: {
          formatter: function (val) {
              return format(new Date(val), 'MMMM d, yyyy');
          }
      }
    },
    series: series,
    xaxis: {
      type: "datetime",
    },
    colors: ["#3958ff","#912ED3","#58dbbd","#3a0ca3","#f72585","#d74836","#909090"],
    stroke: {
      curve: "smooth",
      width: strokeWidth,
    },
    yaxis: [
      {
        tickAmount: 3,
        min: 0,
        labels: {
          formatter: function (number) {
            if (isNil(number)) {
              return;
            }
            return number.toLocaleString("en-US") + ` ${demand ? 'kW' : unit}`;
          },
        },
        forceNiceScale: true,
      }
    ],
    grid: {
      borderColor: "rgba(0,0,0,0.05)",
      padding: {
          right: 30 // Add some right padding to ensure the last label is visible
      }
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 5,
      strokeColors: "#fff",
      strokeWidth: 4,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
    },
  };

  return (
    <div style={{marginTop: 16, marginBottom: 30}}>
      <Chart
        type="line"
        options={options}
        series={series}
        height="320px"
        width="100%"
      />
    </div>
  );
};

export default UsageChart;
