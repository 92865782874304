import React from 'react';
import styled from 'styled-components';
import { dark_blue } from '../../../../utils/colors';

const Container = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

const SectionHeader = styled.h2`
  font-size: 20px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 24px;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 10px;
`;

const Paragraph = styled.p`
    font-size: 14px;
    line-height: 1.6;
`;

const Bullet = styled.li`
    font-size: 14px;
    line-height: 1.5;
`

const Link = styled.div`
    font-size: 14px;
    color: #3958FF;
    transition: .3s;
    font-weight: 500;
    &:hover {
        color: ${dark_blue};
        cursor: pointer;
    }
`

const Overview = ({ handleDocsClick, handleWebhookClick }) => {
  return (
    <Container>
      <SectionHeader>Purpose</SectionHeader>
      <Paragraph>
        The Upward Energy Bill Reader API is designed to simplify the management and extraction of data from energy and water bills. Whether you're working with individual bills or bulk data, this API provides the tools needed to quickly access and process essential billing information. It offers both high-level summaries and detailed line items that can be used for analysis, reporting, or monitoring purposes. This API aims to reduce the complexity of extracting data from bills and streamline the process of integrating this data into your applications and workflows.
      </Paragraph>

      <SectionHeader>Structure</SectionHeader>
      <Paragraph>
        The API is structured around a series of endpoints, each serving a specific function related to data extraction and management:
      </Paragraph>
      <ul>
        <Bullet>
          <strong>Base URL</strong>: All endpoints are accessible under the base URL <code>https://api.buildupward.com/api/</code>.
        </Bullet>
        <Bullet>
          <strong>Endpoints</strong>: The primary functions are divided among several endpoints, organized by the type of data extraction or management task they perform.
          <ul>
            <Bullet><strong>Data Extraction</strong>: Endpoints for simple and detailed data extraction from bills.</Bullet>
            <Bullet><strong>Requests</strong>: Endpoints to check the status of extraction requests and retrieve the extracted data.</Bullet>
            <Bullet><strong>Files</strong>: Endpoints to download the original bill pdfs.</Bullet>
          </ul>
        </Bullet>
      </ul>
      <Link onClick={handleDocsClick}>Click here to view all routes and schemas in the API Documentation</Link>

      <SectionHeader>Webhooks</SectionHeader>
      <Paragraph>
        The API supports webhook integrations, allowing real-time notifications upon completion of data extraction requests. Users can set up a webhook endpoint to receive POST requests from the API server, which includes basic information and the request ID of the approved or declined request.
      </Paragraph>
      <Paragraph><strong>Purpose</strong>: Eliminate the need for constant polling by enabling real-time notifications.</Paragraph>
      <Paragraph><strong>Use Case</strong>: Automated workflows where immediate action is required upon completion of data extraction requests.</Paragraph>
      <Link onClick={handleWebhookClick}>Click here to set your custom webhook link</Link>

      <SectionHeader>Conclusion</SectionHeader>
      <Paragraph>
        The Upward Energy Bill Reader API is a robust solution for extracting, managing, and integrating billing data from energy and water bills. Its versatile endpoints and support for detailed and summary data extraction make it an essential tool for a wide range of applications in energy management, financial analysis, customer service, and more. By leveraging the power of this API, organizations can streamline their data workflows, enhance their analytical capabilities, and improve overall efficiency in handling billing information.
      </Paragraph>
    </Container>
  );
};

export default Overview;