import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import { Checkbox, FormControlLabel, ThemeProvider } from '@mui/material';
import styled from 'styled-components';
import { blueTheme } from '../../../../utils/colors';

const Container = styled.div`
    width: 560px;
    height: 570px;
    border-radius: 5px;
`

const Title = styled.div`
    margin-top: -20px;
    font-size: 20px;
    font-weight: 500;
`;

const SubTitle = styled.div`
    font-size: 13px;
    margin-top: 8px;
    line-height: 1.5;
`;

const SubHeader = styled.div`
    font-size: 18px;
    font-weight: 500;
`

const CheckboxContainer = styled.div`
    width: 528px;
    height: 330px;
    display: grid;
    margin-top: 12px;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    padding: 16px;
    overflow: scroll;
    grid-template-columns: repeat(2, 1fr);
`

const EditColumnsModal = ({ open, handleClose, columnDefs, onColumnToggle, monthly, onMonthlyToggle }) => {
  const [selectedColumns, setSelectedColumns] = useState(columnDefs);

  useEffect(() => {
    setSelectedColumns(columnDefs)
  }, [columnDefs, monthly])

  const handleColumnToggle = (colId) => {
    const newSelectedColumns = selectedColumns.map((col) => {
      if (col.field === colId) {
        return { ...col, hide: !col.hide };
      }
      return col;
    });

    setSelectedColumns(newSelectedColumns);
    onColumnToggle(newSelectedColumns);
  };

  const modalContent = () => {
    return (
      <Container>
        <Title>Edit Meter Data Table</Title>
        <SubTitle>Customize the data format and visible columns for the data table.</SubTitle>
        
        <div style={{marginBottom: 32, marginTop: 32}}>
            <SubHeader>Set Table Format</SubHeader>
            <ThemeProvider theme={blueTheme}>
                <FormControlLabel
                    control={
                        <Checkbox
                        size="small"
                        checked={monthly}
                        onChange={(e) => {
                            if (monthly) {
                                onMonthlyToggle(false)
                            } else {
                                onMonthlyToggle(true)
                            }
                        }}
                        name="Monthly Data"
                        color="primary"
                        />
                    }
                    label="View Data in Monthly Format"
                    sx={{
                        '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        },
                    }}
                />
            </ThemeProvider>
        </div>
        
        <SubHeader>Edit Columns</SubHeader>
        <CheckboxContainer>
          {columnDefs.map((col) => {
            // If the column header is 'Select', return null to render nothing
            if (col.header === 'Select' || col.headerName === 'View') {
              return null;
            }
            return (
              <div style={{ width: 260, marginTop: '-8px' }} key={col.field}>
                <ThemeProvider theme={blueTheme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={!col.hide}
                        onChange={() => handleColumnToggle(col.field)}
                        name={col.header}
                        color="primary"
                      />
                    }
                    label={col.header}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            );
          })}
        </CheckboxContainer>
      </Container>
    );
  };
  
  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default EditColumnsModal;