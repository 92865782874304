import React from "react";
import styled from "styled-components";
import greenCheck from "../../assets/greenCheck.svg"
import hazard from "../../assets/Hazard.svg"
import redX from "../../assets/redX.svg"
import info from "../../assets/blueInfo.svg"
import { dark_orange, light_orange, blue, light_blue } from "../../utils/colors";


export const snackbarStyles = {
  root: {
    flexGrow: 1,
    display: "flex",
    margin: 16,
    justifyContent: "center",
    alignItems: "middle",
  },
  button: {
    margin: 8,
    bordercolor: "#313131",
    color: "#313131",
  },
  success: {
    border: "1px solid #34C302",
    color: "#34C302",
    backgroundColor: '#F3FFF6',
  },
  error: {
    border: "1px solid #FA0E0E",
    color: "#FA0E0E",
    backgroundColor: '#FFF3F3',
  },
  info: {
    border: `1px solid ${blue}`,
    color: blue,
    backgroundColor: light_blue,
  },
  warning: {
    border: `1px solid ${dark_orange}`,
    color: dark_orange,
    backgroundColor: light_orange,
  },
};

// export const buttons = [
//   { variant: "success", message: "Successfully done the operation." },
//   { variant: "error", message: "Something went wrong." },
//   { variant: "warning", message: "Something could go wrong" },
//   { variant: "info", message: "For your info..." },
// ];

const Flex = styled.div`
  display: flex;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`

export const buttons = {
  success: <>
    <Flex>
      <Icon src={greenCheck}/>
      <>Success</>
    </Flex>
  </>,
  error: <>
    <Flex>
      <Icon src={redX}/>
      <>Something Went Wrong</>
    </Flex>
  </>,
  warning: <>
    <Flex>
      <Icon src={hazard}/>
      <>This May Cause an Error</>
    </Flex>
  </>,
  info: <>
    <Flex>
      <Icon src={info}/>
      <>For Your Information</>
    </Flex>
  </>,
};

// const MessageBar = ({type}) => {
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//     return (

//     )
// }

// export default MessageBar;
