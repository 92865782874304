import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes/routesConfig";
import { SnackbarProvider } from "notistack";
import Success from "./components/snackbars/Success";
import Error from "./components/snackbars/Error";
import CopiedToClipboard from "./components/snackbars/CopiedToClipboard";
import { GlobalDataProvider } from "./store/GlobalDataProvider";
import { AuthProvider } from "./store/AuthContext";
import CustomScrollbar from "./components/customScrollBar";

const router = createBrowserRouter(routes);

function App() {
  return (
    <CustomScrollbar style={{ width: '100%', height: '100vh' }}>
      <AuthProvider>
        <GlobalDataProvider>
            <SnackbarProvider
              Components={{
                customSuccess: Success,
                customError: Error,
                copiedSuccess: CopiedToClipboard,
              }}
            >
              <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
            </SnackbarProvider>
        </GlobalDataProvider>
      </AuthProvider>
    </CustomScrollbar>
  );
}

export default App;
