import React, { useState, useEffect, useCallback, useMemo } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { capitalizeFirstLetterOfEachWord } from "../utils/formatNumbers";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton } from "@mui/material";
import Pill from "./buttons/Pill";
import Check from "../assets/Check.svg";
import X from "../assets/X.svg";
import PendingOrange from "../assets/PendingOrange.svg";
import styled from "styled-components";

const GridContainer = styled.div`
    box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
        0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
    border-radius: 8px;
    height: Calc(100vh - 152px);
`

export const dateFormatter = (params) => {
    const value = params.value;
    if (!value || !(value instanceof Date)) return '';
    
    const mm = String(value.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(value.getDate()).padStart(2, '0');
    const yyyy = value.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
};

// Date comparator function for sorting correctly
export const dateComparator = (date1, date2) => {
    if (date1 == null && date2 == null) {
        return 0;
    }
    if (date1 == null) {
        return -1;
    }
    if (date2 == null) {
        return 1;
    }
    return date1 - date2;
};

const preprocessData = (data) => {
    return data.map(item => {
        if (item.dateCreated && item.dateCreated._seconds != null && item.dateCreated._nanoseconds != null) {
            item.dateCreated = new Date(item.dateCreated._seconds * 1000 + item.dateCreated._nanoseconds / 1000000);
        }
        if (item.serviceClass && Array.isArray(item.serviceClass)) {
            item.serviceClass = item.serviceClass.join(', ')
        }
        if (typeof item.serviceClass === 'string') {
            item.serviceClass = capitalizeFirstLetterOfEachWord(item.serviceClass)
        }

        return item;
    });
};

// Status cell renderer component
const StatusCellRenderer = (params) => {
    const status = params.value;

    let icon;
    switch (status) {
        case 'approved':
            icon = <Pill
                icon={Check}
                text={"Complete"}
                color={"#388e3c"}
                background={"#dcedc8"}
            />
            break;
        case 'pending':
            icon = <Pill
                icon={PendingOrange}
                text={"Pending"}
                color={"#e65100"}
                background={"#ffecb3"}
            />
            break;
        case 'declined':
            icon = <Pill
                background={"#ffcdd2"}
                text={"Declined"}
                color={"#d50000"}
                icon={X}
              />
            break;
        default:
            icon = null;
    }

    return <div style={{marginTop: '8px'}}>{icon}</div>;
};

// Delete button renderer component
const DeleteButtonRenderer = (props) => {
    const handleClick = (event) => {
        props.onDelete(props.node.data.requestId, props.node.data.status); // Call the passed delete function with the requestId
    };
    
    return (
        <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
            <IconButton size="small" onClick={handleClick}>
                <DeleteOutlinedIcon style={{ color: '#3958ff' }} fontSize="small" />
            </IconButton>
        </div>
    );
};

const DataGrid = ({ rowData, onRowClick, onDeleteRow }) => {
    const [gridApi, setGridApi] = useState(null);
  
    const cols = [
      {
        field: 'dateCreated',
        width: 120,
        headerName: 'Date',
        valueFormatter: dateFormatter,
        comparator: dateComparator, // Custom sorting
        filter: 'agDateColumnFilter', // Enable date filter
        suppressNavigable: true,
        cellClass: 'no-border',
        key: 'dateCreated',
      },
      {
        field: 'customer',
        flex: 1.5,
        headerName: 'Name',
        filter: 'agTextColumnFilter', // Enable text filter
        suppressNavigable: true,
        cellClass: 'no-border',
        key: 'customer'
      },
      {
        field: 'billsCount',
        width: 120,
        headerName: 'Total Bills',
        filter: 'agNumberColumnFilter' // Enable number filter
      },
      {
        field: 'serviceClass',
        headerName: 'Service Class',
        filter: 'agTextColumnFilter', // Enable text filter
        suppressNavigable: true,
        cellClass: 'no-border',
        key: 'serviceClass',
        flex: 1
      },
      {
        field: 'status',
        width: 125,
        headerName: 'Status',
        filter: 'agTextColumnFilter', // Enable text filter
        cellRenderer: StatusCellRenderer,
        suppressNavigable: true,
        cellClass: 'no-border',
        key: 'status',
      },
      {
        colId: '-actions-',
        headerName: 'Actions', // Add Actions column for the delete button
        cellRenderer: (params) => (
          <DeleteButtonRenderer {...params} onDelete={onDeleteRow} />
        ),
        width: 90, // Adjust the width as needed
        sortable: false,
        suppressNavigable: true,
        cellClass: 'no-border',
        key: '-actions-'
      }
    ];

    function sortGrid(gridApi) {
      const columnState = {
        state: [
          {
            colId: 'dateCreated',
            sort: 'desc',
          },
        ],
      };
      gridApi.applyColumnState(columnState);
    }

    const onGridReady = useCallback((params) => {
      setGridApi(params.api);
      sortGrid(params.api);
      // If you need to set special grid options or callbacks, you can do it here
    }, []);

    const preprocessedRowData = useMemo(() => preprocessData(rowData), [rowData]);

    useEffect(() => {
      if (gridApi) {
        gridApi.setRowData(preprocessedRowData);
      }
    }, [preprocessedRowData, gridApi]);

    const handleRowClick = (data) => {
      onRowClick(data); // handle row click
    };
    
    return (
      <GridContainer className="ag-theme-quartz" >
        <style>
          {`
            .ag-cell:hover {
              cursor: pointer;
            }
          `}
        </style>
        <AgGridReact
          onGridReady={onGridReady}
          rowData={preprocessedRowData}
          columnDefs={cols}
          onCellClicked={(cell) => {
            if (cell.column.getColId() === '-actions-' || cell.data.status !== 'approved') {
              return;
            }
            handleRowClick(cell.data);
          }}
          suppressCellFocus={true}
          animateRows={false}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={[10, 20, 50, 100]}
          frameworkComponents={{
            deleteButtonRenderer: DeleteButtonRenderer,
          }}
          suppressScrollOnNewData={true}
        />
      </GridContainer>
    );
};

export default DataGrid;