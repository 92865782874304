import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const getFormData = async (idToken) => {
  console.log('id token: ', idToken)
  try {
    const data = await axios({
      method: "get",
      url: `${expressHost}/api/getFormData`,
      headers: { formId: idToken },
    });
    return data.data;
  } catch (error) {
    console.log(error)
  }
};
