import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const contactSales = async (idToken) => {
  try {
    const data = await axios({
      method: "get",
      url: `${expressHost}/contact/contactSales`,
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
