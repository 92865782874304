import React from "react";
import { NavContainer, PageContainer } from "./styles";
import NavBar from "../navbar/NavBar";

const Page = ({ currentPage, children }) => {
  return (
    <NavContainer>
      <NavBar currentPage={currentPage} />
      <PageContainer>{children}</PageContainer>
    </NavContainer>
  );
};

export default Page;
