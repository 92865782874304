import React, { useState } from "react";
import {
  Container,
  Header,
  HeaderSubtitle,
  HeaderTitle,
  HeaderActions,
  TableContentContainer,
} from "./styles";
import { tableCellClasses } from "@mui/material/TableCell";
import styled from "styled-components";
import CircularButton from "../../../../components/buttons/CircularButton";
import Plus from "../../../../assets/Plus.svg";
import TableWrapper from "../../../../components/table/index";
import Card from "../../../../components/cards/Card";
import EmptyTableState from "./emptyTableState";
import { useGlobalData } from "../../../../store/GlobalDataProvider";
import { isNil } from "lodash";
import { TableCell, TableRow } from "@mui/material";
import APIKeyModal from "../APIKey";
import HideAndCopy from "../hideAndCopy/index";
import { deleteAPIKey } from "../../apiCalls/deleteAPIKey";
import WebHook from "../webHook/index";
import { useAuth } from "../../../../store/AuthContext";
import UsageBarChart from "./usageBarChart";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000000",
    fontSize: 14,
    // borderBottom: "1px solid rgb(182 180 180)",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 600,
    borderBottom: "1px solid rgb(233 232 232)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontWeight: 500,
  },
}));

function formatDateToMMDDYYYY(dateStr) {
  // Convert to Date object
  const dateObj = new Date(dateStr);

  // Extract components
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();

  // Format mm/dd/yyyy
  return `${month}/${day}/${year}`;
}

const APIKeys = ({apiEnabled, setOpen}) => {
  const { currentUser } = useAuth();
  const [generateAPIKeysModal, setGenerateAPIKeysModal] = useState(false);
  const { userData } = useGlobalData();

  const handleClickGenerateNewApiKey = () => {
    if (apiEnabled) {
      setGenerateAPIKeysModal(true);
    } else {
      setOpen()
    }
  };

  const handleCloseGenerateAPIKeyModal = () => {
    setGenerateAPIKeysModal(false);
  };

  const handleDeleteKey = async (key) => {
    await deleteAPIKey(key, await currentUser.getIdToken());
  };

  const columns = [
    {
      id: "name",
      label: "Description",
      minWidth: 50,
      align: "left",
      width: "150px",
    },
    {
      id: "date",
      label: "Date Created",
      minWidth: 50,
      align: "left",
      width: "150px",
    },
    { id: "key", label: "Key", minWidth: 50, align: "left", width: "400px" },
  ];

  return (
    <>
      <Container>
        <Header>
          <div>
            <HeaderTitle>API Keys</HeaderTitle>
            <HeaderSubtitle>
              Keep track of generated API keys. Generate a key to access
              Upward's API.
            </HeaderSubtitle>
          </div>
          <HeaderActions>
            {(isNil(userData) ||
              isNil(userData.api) ||
              userData.api === null) && (
              <div onClick={handleClickGenerateNewApiKey}>
                <CircularButton
                  icon={Plus}
                  type="secondary"
                  title={"Create API Key"}
                  content={"Create API Key"}
                />
              </div>
            )}
          </HeaderActions>
        </Header>
        <Card>
          {(isNil(userData) ||
            isNil(userData.api) ||
            userData.api === null) && (
            <EmptyTableState handleClick={handleClickGenerateNewApiKey} />
          )}
          {!isNil(userData) &&
            !isNil(userData.api) &&
            userData.api !== null && (
              <TableContentContainer>
                <TableWrapper
                  columns={columns}
                  includePagination={false}
                  includeSort={false}
                >
                      <TableRow>
                        <StyledTableCell
                          align={"left"}
                          sx={{
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            borderBottom: "1px solid rgb(233 232 232)",
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#616161",
                          }}
                        >
                          {userData.api.name}
                        </StyledTableCell>
                        <StyledTableCell
                          align={"left"}
                          sx={{
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            borderBottom: "1px solid rgb(233 232 232)",
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#616161",
                          }}
                        >
                          {!isNil(userData.api) &&
                            !isNil(userData.api) &&
                            !isNil(userData.api.dateCreated) && (
                              <>
                                {formatDateToMMDDYYYY(
                                  userData.api.dateCreated
                                )}
                              </>
                            )}
                        </StyledTableCell>
                        <StyledTableCell
                          align={"left"}
                          sx={{
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            borderBottom: "1px solid rgb(233 232 232)",
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#616161",
                            width: "400px",
                          }}
                        >
                          <HideAndCopy
                            text={userData.api.key}
                            withDelete={true}
                            deleteFunction={() => handleDeleteKey(userData.api.key)}
                          />
                        </StyledTableCell>
                      </TableRow>
                </TableWrapper>
              </TableContentContainer>
            )}
          

        </Card>
      </Container>

      <WebHook />

      <div style={{marginTop: 40}}>
        <HeaderTitle>Usage</HeaderTitle>
        <HeaderSubtitle>
          Track your API usage
        </HeaderSubtitle>
      </div>

      <UsageBarChart monthlyData={userData.overview.apiUsage} />

      <div style={{height: 100}} />

      <APIKeyModal
        open={generateAPIKeysModal}
        handleClose={handleCloseGenerateAPIKeyModal}
      />
    </>
  );
};

export default APIKeys;
