import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  min-width: 1100px;
`;

export const PageContainer = styled.div`
  width: 100%;
  // height: 100vh;
  // background-color: #eff6ff;
  //   background-color: #ffffff;
  background-color: #fdfdfd;
`;
