export const numberWithCommas = (x = "") => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDollars = (x) => {
  var roundedNum = Math.round(x * 100) / 100;
  var roundedToTwoDecimals = roundedNum.toFixed(2);
  return '$ ' + Number(roundedToTwoDecimals).toLocaleString();
}

export const formatWithUnit = (x, unit) => {
  return Math.round(x).toLocaleString() + ' ' + unit
}

export const formatDollarsPerUnit = (x, unit) => {
  var roundedNum = Math.round(x * 10000) / 10000
  var roundedToFixed = unit === 'kWh' ? roundedNum.toFixed(4) : roundedNum.toFixed(2) 
  return roundedToFixed.toLocaleString() + ' $/' + unit
}

export function capitalizeFirstLetterOfEachWord(str) {
  return str.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}