import styled from "styled-components";

export const Eye = styled.img`
  width: 18px;
  height: 18px;
  //   margin-top: 10px;
  //   margin-left: 15px;
  transition: 0.3s;
  //   filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%)
  //     hue-rotate(218deg) brightness(103%) contrast(113%);
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%)
      hue-rotate(218deg) brightness(103%) contrast(113%);
  }
`;
export const Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
