import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const getRequestData = async (requestId, idToken) => {
  try {
    const data = await axios({
      method: "post",
      url: `${expressHost}/dataRequests/getApprovedRequestData`,
      data: {
        requestId: requestId,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
