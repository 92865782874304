import React, { useState } from 'react';
import { useAuth } from '../../../store/AuthContext.js';
import { SquareButton } from '../../../components/buttons/SquareButton.js';
import { sendEmailVerification } from "firebase/auth";
import { TabHeaderSubtitle, TabHeaderTitle } from '../styles.js';

function VerifyEmail({isEmailVerified}) {
  const { currentUser } = useAuth();
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(' ');

  const handleSendVerificationEmail = async () => {
    setLoading(true);
    try {
      await sendEmailVerification(currentUser);
      setEmailSent(true)
      setLoading(false)
      setMessage('Verification email sent. Please allow a few minutes and refresh the page once complete.');
    } catch (error) {
      setMessage('Error sending verification email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
        <div style={{ marginTop: "40px" }}>
            <TabHeaderTitle>Verify Email Address</TabHeaderTitle>
            <TabHeaderSubtitle>{isEmailVerified ? "Your email address has been verified successfully." : "Your email is not yet verified. Verify your email to secure your account."}</TabHeaderSubtitle>
        </div>

        <div onClick={handleSendVerificationEmail} style={{width: 162, marginTop: 16, display: isEmailVerified ? 'none' : ''}}>
            <SquareButton isloading={loading} text={"Send Verification Email"} disabled={emailSent}/>
        </div>
        <TabHeaderSubtitle>{message}</TabHeaderSubtitle>
    </div>
  );
}

export default VerifyEmail;