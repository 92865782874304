import React from 'react';
import Chart from 'react-apexcharts';
import { isNil } from 'lodash';

function getMissingMonths(sortedKeys) {
    const allKeys = [];
    for (let i = 0; i < sortedKeys.length - 1; i++) {
      const current = new Date(sortedKeys[i] + "-01");
      const next = new Date(sortedKeys[i + 1] + "-01");
      let tempDate = new Date(current);
  
      while (tempDate < next) {
        tempDate.setMonth(tempDate.getMonth() + 1);
        const yearMonth = tempDate.toISOString().slice(0, 7);
        if (yearMonth !== sortedKeys[i + 1]) {
          allKeys.push(yearMonth);
        }
      }
    }
    return allKeys;
  }
  
  function formatSeriesData(data) {
    // Step 1: Extract and sort the keys chronologically
    const sortedKeys = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
  
    // Step 2: Limit to the most recent 6 months from provided keys
    const limitedKeys = sortedKeys.slice(-6);
  
    // Step 3: Fill in gaps with zeroes
    const missingMonths = getMissingMonths(limitedKeys);
    const filledKeys = [...new Set([...limitedKeys, ...missingMonths])].sort((a, b) => new Date(a) - new Date(b));
    const filledData = filledKeys.reduce((acc, key) => {
      acc[key] = data[key] || 0;
      return acc;
    }, {});
  
    // Step 4: Format the keys to "MMM YYYY"
    const categories = filledKeys.map(key => {
        var date = new Date(key + "-15");
        const options = { year: 'numeric', month: 'short' };
        return date.toLocaleDateString(undefined, options);
    });
  
    const seriesData = filledKeys.map(key => filledData[key]);
  
    return {
      categories: categories,
      series: [{
        name: "Usage",
        data: seriesData
      }]
    };
  }

const UsageBarChart = ({ monthlyData }) => {
    const { categories, series } = formatSeriesData(monthlyData)

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
        },
        grid: {
            borderColor: "rgba(0,0,0,0.05)",
            padding: {
                right: 30 // Add some right padding to ensure the last label is visible
            }
        },
        legend: {
            offsetY: 2,
            show: false,
            showForSingleSeries: true,
            position: 'bottom',
            itemMargin: {
                horizontal: 12,
            },
            fontSize: '13px',
            fontFamily: 'Inter',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: 16,
                borderRadius: 8, // Adjust this value to round the corners of the bars
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'all'
            },
        },
        colors: ["#3958ff","#912ED3","#58dbbd","#3a0ca3","#f72585","#d74836","#909090"],
        xaxis: {
            categories: categories,
        },
        yaxis: [
            {
              tickAmount: 3,
              min: 0,
              labels: {
                formatter: function (number) {
                  if (isNil(number)) {
                    return;
                  }
                  return number.toLocaleString("en-US") + ` Bills`;
                },
              },
              forceNiceScale: true,
            }
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical', // You can also use 'horizontal'
                shadeIntensity: 0.25,
                gradientToColors: undefined, // Optional: specify colors here
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 100], // Define gradient stop points
            },
        },
    };

    return (
        <div style={{marginTop: 16}}>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="720px"
                height="240px"
            />
        </div>
    );
};

export default UsageBarChart