import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isUser } from "../../store/firebase";

const Root = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isUser()) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  });
  return <></>;
};

export default Root;
