import React from "react";
import styled from "styled-components";
import { dark_blue, blue } from "../../utils/colors";

const Button = styled.div`
  width: max-content;
  height: 30px;
  background-color: ${(props) =>
    props.disabled
      ? "rgba(0,0,0,0.2)"
      : props.color
      ? props.color
      : props.type === "primary"
      ? "#fff"
      : blue};
  box-shadow: 2px 6px 8px 0px rgba(65, 65, 90, 0.3);
  border-radius: 50px;
  padding: 15px;
  padding-right: 17px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  gap: 6px;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "rbga(0,0,0,0.2)"
        : props.type === "primary"
        ? "#fff"
        : dark_blue};
  }
`;

const Icon = styled.img`
  height: 15px;
  width: 15px;
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: white;
  width: max-content;
`;
const Circular = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${(props) =>
    props.disabled
      ? "rgba(0,0,0,0.2)"
      : props.color
      ? props.color
      : props.type === "primary"
      ? "#fff"
      : blue};
  box-shadow: 2px 6px 8px 0px rgba(65, 65, 90, 0.3);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    animation: animate 2s linear infinite;
    cursor: pointer;
  }
  @keyframes animate {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 158, 31, 0.7);
    }
    40% {
      box-shadow: 0 0 0 15px rgba(255, 158, 31, 0);
    }
    80% {
      box-shadow: 0 0 0 15px rgba(255, 158, 31, 0);
    }
    1000% {
      box-shadow: 0 0 0 0 rgba(255, 158, 31, 0);
    }
  }
`;

const CircularIcon = styled.img`
  height: 17px;
  width: 17px;
`;

const CircularButton = ({
  icon,
  type,
  title = null,
  content = null,
  disabled = false,
  color = null,
  noText = false,
}) => {
  if (noText) {
    return (
      <Circular type={type} disabled={disabled} color={color}>
        <CircularIcon src={icon} title={title} />
      </Circular>
    );
  } else {
    return (
      <Button type={type} disabled={disabled} color={color}>
        <Icon src={icon} title={title} />
        <Content>{content}</Content>
      </Button>
    );
  }
};

export default CircularButton;
