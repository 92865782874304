import Auth from "../pages/auth/auth.js";
import ErrorRoute from "../pages/errorRoute/errorRoute.js";
import DataRequests from "../pages/dataRequests/dataRequests";
import Home from "../pages/home/home.js";
import Root from "../pages/root/root.js";
import UploadEnergyBills from "../pages/dataRequests/pages/uploadBills/uploadEnergyBills.js";
import API from "../pages/api/index";
import CustomerBillsUpload from "../pages/customerUpload/index.js";
import BillsUploadResults from "../pages/billsUploadResults/index.js";
import Documentation from "../pages/documentation/index.js";
import Settings from "../pages/settings/index.js";

const routes = [
  {
    path: "/login",
    element: <Auth />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorRoute />,
  },
  {
    path: "/requests",
    element: <DataRequests />,
  },
  {
    path: "/utilityApi",
    element: <Auth />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/upload-energy-bills",
    element: <UploadEnergyBills />,
  },
  {
    path: "/signup",
    element: <Auth />,
  },
  {
    path: "/api",
    element: <API />,
  },
  {
    path: "/customer-bills-upload/:api/:encryptedId",
    element: <CustomerBillsUpload/>,
  },
  {
    path: "/error",
    element: <ErrorRoute />,
  },
  {
    path: "/bills-data/:requestId",
    element: <BillsUploadResults/>,
  },
  {
    path: "/apiDocumentation",
    element: <Documentation />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
];

export default routes;
