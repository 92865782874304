import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import linkIcon from "../../../assets/Link.svg"
import { TextField } from "@mui/material";
import { useGlobalData } from "../../../store/GlobalDataProvider";
import { SquareButton } from "../../../components/buttons/SquareButton";

const Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessText = styled.div`
  margin-top: 32px;
  font-size: 26px;
  font-weight: 500;
`;

const Text = styled.div`
  color: #000000;
  font-size: 13px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Check = styled.img`
    width: 48px;
    height: 48px;
    margin: 0 auto;
`

const LinkModal = ({ open, handleClose }) => {
  const [buttonText, setButtonText] = useState("Copy")
  const { uploadLink } = useGlobalData();

  const handleClickCopy = () => {
    navigator.clipboard.writeText(uploadLink)
    setButtonText("Copied!")
  }

  const modalContent = () => {
    return (
      <Container>
        <Check src={linkIcon} />
        <SuccessText>Your Bills Upload Link</SuccessText>
        <Text>
          Share this link with your customers and partners and they can upload bills to you directly. You will see uploaded bills in the platform automatically as they are uploaded. 
        </Text>
        <div style={{marginTop: "32px", width: "100%"}}>
          <TextField fullWidth size="small" variant="outlined" defaultValue={uploadLink} />
        </div>
        <div style={{marginTop: "16px"}} onClick={() => {handleClickCopy()}}>
          <SquareButton text={buttonText} width={"100px"}/>
        </div>
      </Container>
    );
  };

  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default LinkModal;
