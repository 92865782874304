export const addDataDocumentTitle = "Upload Energy Bills";
export const addDataDocumentDescription =
  "Upload PDFs or images of energy bills directly and extract data.";
export const addDataMeterTitle = "Smart Utility Meters";
export const addDataMeterDescription =
  "Pull data directly from utility smart meters by getting customer authorization.";
export const addDataConEdTitle = "Con Edison Benchmarking";
export const addDataConEdDescription =
  "Collect data through the Con Edison benchmarking process. (does not include cost)";

export const conEdBenchmarkingRequestSubHeader =
  "Request aggregated building data through the Con Edison benchmarking process.  Only for building over 25,000 sqft.";
export const conEdBenchmarkingRequestHeader = "Con Ed Benchmarking Request";

export const smartUtilityMetersHeader = "Smart Utility Meters";
export const smartUtilityMetersSubHeader =
  "Send an automated email to request access to interval energy meter data from your customer.";
export const uploadEnergyBillsHeader = "Upload Energy Bills";
export const uploadEnergyBillsSubHeader =
  "Select energy bills or drag and drop below to upload";

export const uploadEnergyBillsNote =
  "Note: Please upload only one building's worth of bills at a time.";

export const downloadAsCSVHeader = "Download as CSV";
export const downloadAsCSVDescription = "Download CSV to your computer.";
export const addToBuidlingHeader = "Add to building";
export const addToBuildingDescription =
  "Add uploaded data to an existing building in Upward.";
export const createNewBuildingHeader = "Create new building";
export const createNewBuildingDescription =
  "Use uploaded data to create a new building in Upward.";
