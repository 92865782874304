import React, { useEffect, useState, useRef } from 'react';
import { auth } from '../../../store/firebase.js';
import { useAuth } from '../../../store/AuthContext.js';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { SquareButton } from '../../../components/buttons/SquareButton.js';
import { RecaptchaVerifier, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import MFASuccessModal from './MFASuccessModal.js';
import { useNavigate } from 'react-router-dom';
import { TabHeaderSubtitle, TabHeaderTitle } from '../styles.js';

const TextFieldButtonContainer = styled.div`
  width: 600px;
  display: flex;
  gap: 8px;
`;

const TextFieldContainer = styled.div`
  width: 300px;
  height: 33px;
`;


function Enable2FA({isEmailVerified}) {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [error, setError] = useState((false))
  const [errorMessage, setErrorMessage] = useState(null)
  const recaptchaVerifierRef = useRef(null); // reference for reCAPTCHA instance
  const [isMFAEnrolled, setIsMFAEnrolled] = useState(false)
  const [open, setOpen] = useState(false)
  const [verifyError, setVerifyError] = useState(false)

  const handleClose = () => {
    setOpen(false)
    navigate("/home")
  }

  useEffect(() => {
    setIsMFAEnrolled(multiFactor(currentUser).enrolledFactors && multiFactor(currentUser).enrolledFactors.length > 0)
    setError(!isEmailVerified)
    setErrorMessage((!isEmailVerified) ? 'Please verify your email above first.' : null)
    
    // Create the reCAPTCHA verifier instance only if it doesn't exist already
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
      });
    };

  }, [isEmailVerified])

  const checkPhoneNumberFormat = (str) => {
    const numbers = str.match(/\d/g).join('');
    const regex = /^\d{10,11}$/;
    return regex.test(numbers);
  }

  const formatPhoneNumber = (str) => {
    const numbers = str.match(/\d/g).join('');
    const regex = /^\d{10}$/;
    if(regex.test(numbers)) {
      return '+1' + numbers
    } else {
      return '+' + numbers
    }
  }

  const handleEnable2FA = async () => {
    if (checkPhoneNumberFormat(phoneNumber)) {
      setError(false)
      setErrorMessage('')
      setLoading(true);
      try {

        // Create a PhoneAuthProvider instance
        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // fromat phone number
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber)

        // create multifactor session
        const session = await multiFactor(currentUser).getSession();

        // Send verification code to the phone number
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          {
            phoneNumber: formattedPhoneNumber,
            session: session,
          },
          recaptchaVerifierRef.current
        );

        setVerificationId(verificationId);
      } catch (error) {
        setError(true)
        setErrorMessage('Recent login required, please logout and try again. If this error persists contact support.')
        console.error("Error enabling 2FA", error);
      } finally {
        setLoading(false);
      }
    
    } else {
      setError(true)
      setErrorMessage('Invalid phone number')
    }
  };

  const handleVerify2FA = async () => {
    setVerifyError(false)
    setLoadingTwo(true);
    try {
      if (verificationId) {
        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

        // Enroll the second factor on the user
        await multiFactor(currentUser).enroll(multiFactorAssertion, 'Your Phone Number');
        console.log("2FA enabled for the user");

        // Fetch updated user information
        await currentUser.reload();
        setCurrentUser(auth.currentUser);

        // open success modal
        setOpen(true)
      }
    } catch (error) {
      setVerifyError(true)
      console.error("Error during 2FA enrollment", error);
    } finally {
      setLoadingTwo(false);
    }
  };

  return (
    <div>
      <div style={{ marginTop: "40px", marginBottom: "16px" }}>
        <TabHeaderTitle>Enable 2-Factor Authentication</TabHeaderTitle>
        <TabHeaderSubtitle>
          {isMFAEnrolled ? "This account is already enrolled in 2-Factor Authentication" : "Enter your phone number to enable 2 factor authentication and secure your account. If you enable 2FA you are opting in to receive SMS messages for Upward for authentication purposes, and standard rates will apply."}
        </TabHeaderSubtitle>
      </div>

      {!isMFAEnrolled && <TextFieldButtonContainer>
        <TextFieldContainer>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={phoneNumber}
            placeholder="Phone Number"
            disabled={loading}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={error}
            helperText={errorMessage}
            sx={{
              "& .MuiInputBase-root": {
                height: 34,
              },
              "& .MuiOutlinedInput-input": {
                padding: '8.5px 14px',
                fontSize: '14px'
              },
              "& .MuiInputLabel-root": {
                transform: 'translate(12px, 7px) scale(1)', // Adjust the label position
                fontSize: '14px'
              },
              "& .MuiInputLabel-shrink": {
                transform: 'translate(14px, -9px) scale(0.85)', // Adjust the label position when shrunk
              },
            }}
          />
        </TextFieldContainer>
        <div
          onClick={handleEnable2FA}
        >
          <SquareButton text="Send Verification Code" width="180px" isloading={loading} disabled={!phoneNumber}/>
        </div>
      </TextFieldButtonContainer>}

      {verificationId && (
        <div>
          <div style={{height: 40}} />
          <TabHeaderTitle>Enter Verification Code</TabHeaderTitle>
          <div style={{height: 16}} />
          <TextFieldButtonContainer>
            <TextFieldContainer>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={verificationCode}
                placeholder="Verification Code"
                disabled={loading}
                onChange={(e) => setVerificationCode(e.target.value)}
                error={verifyError}
                helperText={verifyError && 'Unrecognized verification code.'}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 34,
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: '8.5px 14px',
                    fontSize: '14px'
                  },
                  "& .MuiInputLabel-root": {
                    transform: 'translate(12px, 7px) scale(1)', // Adjust the label position
                    fontSize: '14px'
                  },
                  "& .MuiInputLabel-shrink": {
                    transform: 'translate(14px, -9px) scale(0.85)', // Adjust the label position when shrunk
                  },
                }}
              />
            </TextFieldContainer>
            <div onClick={handleVerify2FA}>
              <SquareButton text="Verify" width="180px" isloading={loadingTwo} disabled={!phoneNumber}/>
            </div>
          </TextFieldButtonContainer>
        </div>
      )}
      <div id="recaptcha-container"></div>
      <MFASuccessModal 
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Enable2FA;