import React from "react";
import styled from "styled-components";
import link from "../../assets/Link.svg"

const CircularLinkButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: white;
  box-shadow: 2px 6px 8px 0px rgba(65, 65, 90, 0.3);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    animation: link-animation 2s linear infinite;
    cursor: pointer;
  }
  @keyframes link-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(57, 88, 255, 0.7);
    }
    40% {
      box-shadow: 0 0 0 15px rgba(57, 88, 255, 0);
    }
    80% {
      box-shadow: 0 0 0 15px rgba(57, 88, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(57, 88, 255, 0);
    }
  }
`;

const CircularIcon = styled.img`
  height: 17px;
  width: 17px;
`;

const LinkButton = () => {
    return (
      <CircularLinkButton>{
            <CircularIcon src={link} />
      }</CircularLinkButton>
    );
};

export default LinkButton;
