import React, { forwardRef } from "react";
import { SnackbarContent } from "notistack";
import styled from "styled-components";
import { dark_red, light_red } from "../../utils/colors";
import X from "../../assets/X.svg";

const Container = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid ${dark_red};
  background-color: ${light_red};
  border-radius: 3px;
  min-width: 200px;
  align-items: center;
  gap: 15px;
`;

const Icon = styled.img`
  height: 14px;
  width: 14px;
`;

const Text = styled.div`
  color: ${dark_red};
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
`;

const Error = forwardRef((props, ref) => {
  return (
    <SnackbarContent ref={ref}>
      <Container>
        <Icon src={X} />
        <Text>Something went wrong</Text>
      </Container>
    </SnackbarContent>
  );
});

Error.displayName = "Success";

export default Error;
