import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { isNil } from "lodash";

const firebaseConfig = {
  apiKey: "AIzaSyB-umvPxuFbLWIHygAjzVM41mK5GqsfFBM",
  authDomain: "upward-a899f.firebaseapp.com",
  projectId: "upward-a899f",
  storageBucket: "upward-a899f.appspot.com",
  messagingSenderId: "3970349634",
  appId: "1:3970349634:web:ca5becec7ee881a2f20bdb",
  measurementId: "G-REQGM2X6FR",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };

export const signInUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    console.log(error);
  }
};

export const isUser = () => {
  if (!isNil(auth.currentUser)) {
    return true;
  } else {
    return false;
  }
};

export const signOutUser = () => {
  auth
    .signOut()
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
