import React, { useState, useEffect } from 'react';
import { auth } from '../../../store/firebase.js';
import { PhoneAuthProvider, RecaptchaVerifier, PhoneMultiFactorGenerator } from 'firebase/auth';
import { SquareButton } from '../../../components/buttons/SquareButton.js';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import { dark_blue } from '../../../utils/colors.js';
import { useNavigate } from 'react-router-dom';

const HeaderTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const HeaderSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  line-height: 1.5;
  width: 500px;
  margin-bottom: 24px;
`;

function MultiFactorAuth({ resolver, reload }) {
    const navigate = useNavigate();
    const [recaptchaSolved, setRecaptchaSoved] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [error, setError] = useState(false)

    useEffect(() => {
        window.addEventListener('error', recaptchaErrorHandler);
        setUpRecaptcha();

        // Cleanup reCAPTCHA on component unmount
        return () => {
            window.removeEventListener('error', recaptchaErrorHandler);
        };
    }, []);

    useEffect(() => {
        if (recaptchaSolved) {
            try {
                sendCode();
            } catch (e) {
                console.log(e);
            }
        }
    }, [recaptchaSolved]);

    const recaptchaErrorHandler = (event) => {
        console.log(event);
        if (event.message.includes("auth/network-request-failed")) {
            // Prevent the default handling of this specific error
            event.preventDefault();
        }
    };

    const setUpRecaptcha = async () => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container',
                {
                    size: 'invisible',
                },
            );

            await window.recaptchaVerifier.verify();
            setRecaptchaSoved(true);
        } catch (e) {
            console.log(e);
            // Handle the specific error
            if (e.code === 'auth/network-request-failed') {
                // Handle network errors
                console.log(e);
            }
        }
    };

    const sendCode = async () => {
        try {
            const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
            };

            const phoneAuthProvider = new PhoneAuthProvider(auth);

            // Send verification code to the phone number
            const vId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                window.recaptchaVerifier
            );

            setVerificationId(vId);
        } catch (e) {
            console.log(e);
            // Handle the specific error
            if (e.code === 'auth/network-request-failed') {
                // Handle network errors
            }
        }
    };

    const resetComponent = () => {
        setRecaptchaSoved(false);
        setVerificationCode('');
        setVerificationId(null);
        setUpRecaptcha();
    };

    const verifyCode = async () => {
        try {
            const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
            await resolver.resolveSignIn(multiFactorAssertion);
            navigate('/home');
            console.log('success');
        } catch (e) {
            setError(true)
            console.log(e);
        }
    };

    return (
        <div>
            <div style={{marginTop: 16, display: recaptchaSolved ? 'none' : 'flex', justifyContent: 'center' }} id="recaptcha-container"></div>
            <HeaderTitle>2 Factor Authentication</HeaderTitle>
            <HeaderSubtitle>Enter the confirmation code sent to your phone.</HeaderSubtitle>
            <TextField
                fullWidth
                id="code"
                label="Verification Code"
                placeholder="••••••"
                inputProps={{ style: { fontSize: 14, height: 14 } }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setVerificationCode(e.target.value)}
                error={error}
                helperText={error && "Unrecognized verification code."}
            />
            <div style={{width: 162, margin: '0 auto', marginTop: 16}} onClick={verifyCode}>
                <SquareButton text={"Submit"} />
            </div>
        </div>
    );
}

export default MultiFactorAuth;