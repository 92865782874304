import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const generateAPIKey = async (name, idToken) => {
  try {
    const data = await axios({
      method: "POST",
      url: `${expressHost}/apiAdmin/generateKey`,
      data: {
        name: name,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (e) {
    console.log(e)
  }
};
