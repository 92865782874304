import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { black, blue, grey, white } from "../../utils/colors";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../assets/UploadIcon.svg";
import { isNil } from "lodash";
import Document from "../../assets/Document.svg";
import Trash from "../../assets/Trash.svg";
import { useNavigate } from "react-router-dom";
import FormButton from "../../components/buttons/FormButton";
import Input from "../../components/Input";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../components/snackbars/MessageBar";
import { getCompanyInfo } from "./apiCalls/getCompanyInfo";
import {
  uploadPDFBillsShared,
  uploadPDFBillsSharedApi,
} from "./apiCalls/uploadPDFBills";
import SuccessModal from "./components/successModal";
import { getFormData } from "./apiCalls/getFormData";
import LoadingState from "../billsUploadResults/pages/loadingState";
import { CircularProgress } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 200px auto;
  //   align-items: center;
  width: 470px;
`;

const Header = styled.div`
  color: ${black};
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SubHeader = styled.div`
  color: ${black};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 24px;
  margin-top: 16px;
`;

const UploadBillsBody = styled.div`
  display: flex;
  gap: 15px;
`;

const IconTextContainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 200px;
  //   border-radius: 30px;
  height: 100px;
  //   transition: 0.3s;
  padding-top: 40px;
`;

const DragDropIcon = styled.img`
  margin-left: 85px;
  opacity: 0.2;
  //   transition: 0.3s;
`;

const DragDropText = styled.div`
  font-size: 12px;
  color: ${(props) => (props.error ? "#FB2828" : "#9F9F9F")};
  width: 100%;
  line-height: 1.5;
  text-align: center;
  margin-top: 5px;
  //   transition: 0.3s;
`;

const DragDropArea = styled.div`
  width: 231px;
  height: 178px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  //   transition: 0.3s;
  background-color: ${white};
  &:hover {
    // background-color: #eff2ff;
    // background-color: #dbeafe;
    background-color: #f3f6ff;
  }
  &:hover ${DragDropText} {
    ${(props) => (props.error ? "" : "color:" + blue)}
  }
  &:hover ${DragDropIcon} {
    opacity: 1;
    filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%)
      hue-rotate(218deg) brightness(103%) contrast(113%);
  }
  &:hover ${IconTextContainer} {
    // background-color: #f3f6ff;
    // background-color: #dbeafe;
    background-color: #f3f6ff;
  }
`;

const UploadedBillsContainer = styled.div`
  border-radius: 5px;
  border: 1px solid ${grey};
  padding: 16px;
  height: 178px;
  width: 231px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  background-color: ${white};
`;

const FileItem = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const FileIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const FileName = styled.div`
  color: ${black};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
  gap: 30px;
  align-items: center;
  margin-top: 10px;
`;

const SecondaryAction = styled.div`
  //   padding-bottom: 1px;
  padding: 0 3px;
  color: ${blue};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${blue};
  }
`;

const TrashIcon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(35%) sepia(23%) saturate(4347%)
      hue-rotate(0deg) brightness(103%) contrast(113%);
  }
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  // background-color: #eff6ff;
  // background-color: #ffffff;
  background-color: #fdfdfd;
`;

const fileTypes = ["jpg", "jpeg", "png", "pdf", "tiff"];

const CustomerBillsUpload = () => {
  const { api, encryptedId } = useParams();
  const apiCheck = api === "api";
  const [companyName, setCompanyName] = useState(null);
  const [file, setFile] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [nameOfCustomer, setNameOfCustomer] = useState(
    apiCheck ? "name" : null
  );
  const { enqueueSnackbar } = useSnackbar();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        var company = apiCheck
          ? await getFormData(encryptedId)
          : await getCompanyInfo(encryptedId);
        setCompanyName(company.data);
      } catch {
        navigate("/error");
      }
    };
    fetchData();
  }, []);

  const handleNameChange = (value) => {
    setNameOfCustomer(value);
  };

  const handleChange = (f) => {
    function filterFilesBySize(fileList) {
      const maxSize = 15 * 1024 * 1024; // 25MB in bytes
      const filteredFiles = [];

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.size <= maxSize) {
          filteredFiles.push(file);
        }
      }

      return filteredFiles;
    }

    if (file === null) {
      setFile(filterFilesBySize(f));
    } else if (f === null) {
      setFile(null);
    } else {
      const joined = Array.from(file).concat(Array.from(filterFilesBySize(f)));
      setFile(joined);
    }

    if (filterFilesBySize(f).length === f.length) {
      setTypeError(false);
    } else {
      setTypeError(true);
    }
  };

  const getText = (error, files) => {
    if (error) {
      return "Unsupported file type - PDF, JPEG, or PNG under 15MB only";
    } else if (files === null) {
      return "Upload or drop bills here";
    } else if (files.length === 1) {
      return "1 file ready";
    } else {
      return `${files.length} files ready`;
    }
  };

  const handleUploadBills = async () => {
    if (!isUploading) {
      setIsUploading(true);
      try {
        if (apiCheck) {
          await uploadPDFBillsSharedApi({
            idToken: encryptedId,
            files: file,
          });
        } else {
          await uploadPDFBillsShared({
            files: file,
            customer: nameOfCustomer,
            idToken: encryptedId,
          });
        }

        setIsUploading(false);
        setFile(null);
        setSuccessModalOpen(true);
      } catch (error) {
        enqueueSnackbar(buttons.error, { style: snackbarStyles.error });
        setIsUploading(false);
        console.log("error uploading bills", error);
      }
    }
  };

  const onClickTrash = (e, index) => {
    var newFiles = [...file];
    newFiles.splice(index, 1);
    setFile(newFiles);
  };

  return (
    <PageContainer>
      {companyName ? (
        <Container>
          <Header>{"Share Bills with " + companyName}</Header>
          <SubHeader>{`${companyName} is requesting your energy bills. Enter your name, and click or drag and drop your bills below to share them. If you have multiple buildings, make a separate upload for each building.`}</SubHeader>
          <div style={{ display: apiCheck ? "none" : "" }}>
            <Input
              title={"Name"}
              value={nameOfCustomer}
              setChangeInParent={handleNameChange}
            />
          </div>
          <div style={{ height: "16px" }}></div>
          <UploadBillsBody>
            <FileUploader
              multiple={true}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onTypeError={(err) => {
                setTypeError(true);
              }}
              fileOrFiles={"/TestBill.pdf"}
              children={
                <DragDropArea error={typeError}>
                  <IconTextContainer>
                    <DragDropIcon src={UploadIcon} />
                    <DragDropText error={typeError}>
                      {getText(typeError, file)}
                    </DragDropText>
                  </IconTextContainer>
                </DragDropArea>
              }
            />
            <UploadedBillsContainer>
              {!isNil(file) &&
                Array.isArray(file) &&
                file.map((f, i) => {
                  return (
                    <FileItem>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <FileIcon src={Document} />
                        <FileName>{f.name}</FileName>
                      </div>

                      <TrashIcon
                        src={Trash}
                        title={"Delete"}
                        onClick={(e) => onClickTrash(e, i)}
                      />
                    </FileItem>
                  );
                })}
              {!isNil(file) &&
                !Array.isArray(file) &&
                Object.entries(file).map((f, i) => {
                  return (
                    <FileItem>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <FileIcon src={Document} />
                        <FileName>{f[1].name}</FileName>
                      </div>

                      <TrashIcon
                        src={Trash}
                        title={"Delete"}
                        onClick={(e) => onClickTrash(e, i)}
                      />
                    </FileItem>
                  );
                })}
            </UploadedBillsContainer>
          </UploadBillsBody>
          <Actions>
            <SecondaryAction onClick={() => navigate(-1)}>Back</SecondaryAction>
            <FormButton
              content={"Upload"}
              height={"28px"}
              width={"93px"}
              disabled={
                file && !isNil(nameOfCustomer) && nameOfCustomer !== ""
                  ? false
                  : true
              }
              isloading={isUploading}
              handleClick={handleUploadBills}
            />
          </Actions>
        </Container>
      ) : (
        <div style={{ width: 40, margin: '0 auto', marginTop: 300}}>
          <CircularProgress
            size={40}
            style={{ color: "#3958FF" }}
          />
        </div>
      )}
      <SuccessModal
        open={successModalOpen}
        handleClose={handleSuccessModalClose}
        company={companyName}
      />
    </PageContainer>
  );
};

export default CustomerBillsUpload;
