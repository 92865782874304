import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AuthContentContainer } from "../../../components/styled/AuthContentContainer";
import TextField from "@mui/material/TextField";
import FormButton from "../../../components//buttons/FormButton";
import Checkbox from "@mui/material/Checkbox";
import { dark_blue } from "../../../utils/colors";
import { signUp } from "../apiCalls/signUp";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../../../store/firebase";

const NameContainer = styled.div`
  display: flex;
  gap: 0px;
`;

const ButtonContainer = styled.div`
  margin-left: 138px;
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
`;

const CheckboxText = styled.div`
  font-size: 12px;
  margin-top: 12px;
`;

const ContractSpan = styled.span`
  color: #3958ff;
  cursor: pointer;
  font-weight: 500;
  transition: 0.03s;
  &:hover {
    color: ${dark_blue};
    text-decoration: underline;
  }
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: red;
`;

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(false);

  const [checked, setChecked] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(false);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError(false);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
    setCompanyError(false);
  };

  const handleCheckedChange = () => {
    checked ? setChecked(false) : setChecked(true);
  };

  const handleConfirmPasswordError = (e) => {
    if (e.target.value !== password) {
      setConfirmPasswordError(true);
    }
  };

  const validateEmail = (email) => {
    return email.match(
      // eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleEmailError = () => {
    setEmailError(!validateEmail(email));
  };

  const handlePasswordError = (e) => {
    if (e.target.value.length < 6) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if ((confirmPassword !== "") & (e.target.value !== confirmPassword)) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const checkIfEmpty = () => {
    if (firstName === "") {
      return true;
    }
    if (lastName === "") {
      return true;
    }
    if (company === "") {
      return true;
    }
    if (email === "") {
      return true;
    }
    if (password === "") {
      return true;
    }
    if (confirmPassword === "") {
      return true;
    }
    if (checked === false) {
      return true;
    }
    if (password !== confirmPassword) {
      return true;
    }

    return false;
  };

  const handleEnter = async () => {
    setLoading(true);
    try {
      if (
        !firstNameError &&
        !lastNameError &&
        !companyError &&
        !emailError &&
        !passwordError &&
        !confirmPasswordError
      ) {
        await signUp(
          firstName + " " + lastName,
          company,
          email,
          password
        );
        await signInWithEmailAndPassword(auth, email, password)
        navigate("/home");
      }
      setLoading(false);
    } catch (error) {
      if (error.message.includes("auth/email-already-exists")) {
        setErrorText("Email address already in use");
      } else {
        setErrorText("Error please try again");
      }
      setLoading(false);
    }
  };

  return (
    <>
      <AuthContentContainer>
        <NameContainer>
          <TextField
            id="firstName"
            label="First Name"
            placeholder="John"
            style={{ width: 175, marginRight: 10 }}
            inputProps={{
              style: { fontSize: 14, height: 14 },
            }}
            InputLabelProps={{ shrink: true }}
            error={firstNameError}
            onChange={handleFirstNameChange}
          />
          <TextField
            id="lastName"
            label="Last Name"
            placeholder="Doe"
            style={{ width: 175 }}
            inputProps={{
              style: { fontSize: 14, height: 14 },
            }}
            InputLabelProps={{ shrink: true }}
            error={lastNameError}
            onChange={handleLastNameChange}
          />
        </NameContainer>
        <TextField
          fullWidth
          id="company"
          label="Company"
          placeholder="Company Name"
          inputProps={{
            style: { fontSize: 14, height: 14 },
          }}
          InputLabelProps={{ shrink: true }}
          error={companyError}
          onChange={handleCompanyChange}
        />
        <TextField
          fullWidth
          id="email"
          label="Email"
          placeholder="johndoe@mail.com"
          inputProps={{
            style: { fontSize: 14, height: 14 },
          }}
          InputLabelProps={{ shrink: true }}
          error={emailError}
          onChange={handleEmailChange}
          onBlur={handleEmailError}
          helperText={emailError ? "Invalid email address" : ""}
        />
        <TextField
          fullWidth
          id="password"
          label="Password"
          type="password"
          placeholder="••••••••"
          inputProps={{
            style: { fontSize: 14, height: 14 },
          }}
          InputLabelProps={{ shrink: true }}
          onChange={handlePasswordChange}
          onBlur={handlePasswordError}
          error={passwordError}
          helperText={
            passwordError ? "Password must be 6 characters minimum" : ""
          }
        />
        <TextField
          fullWidth
          id="password"
          label="Confirm Password"
          type="password"
          placeholder="••••••••"
          inputProps={{
            style: { fontSize: 14, height: 14 },
          }}
          InputLabelProps={{ shrink: true }}
          onChange={handleConfirmPasswordChange}
          onBlur={handleConfirmPasswordError}
          error={confirmPasswordError}
          helperText={confirmPasswordError ? "Passwords do not match" : ""}
        />
      </AuthContentContainer>

      <CheckboxContainer>
        <Checkbox size="small" onChange={handleCheckedChange} />
        <CheckboxText>
          By continuing, you agree to Upward's{" "}
          <a href="/privacy.html" target="blank"><ContractSpan >Privacy Policy</ContractSpan></a>.
        </CheckboxText>
      </CheckboxContainer>

      <ErrorText>{errorText}</ErrorText>

      <ButtonContainer>
        <FormButton
          handleClick={handleEnter}
          isloading={loading}
          content={"Sign Up"}
          width={"84px"}
          height={"28px"}
          disabled={checkIfEmpty()}
        />
      </ButtonContainer>
    </>
  );
};

export default Signup;
