export const downloadFile = (data, filename, type) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;

  if (type === "csv") {
    link.setAttribute("download", filename + ".csv");
  } else if (type === "zip") {
    link.setAttribute("download", filename + ".zip");
  }

  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const blob2string = async (blob) => {
  return new TextDecoder().decode(await blob.arrayBuffer());
};
