import React from "react";
import styled from "styled-components";
import Hazard from "../../assets/Hazard.svg"
import { dark_orange } from "../../utils/colors";

const CircularHazard = styled.div`
  height: 26px;
  width: 26px;
  background-color: white;
  box-shadow: 2px 6px 8px 0px rgba(65, 65, 90, 0.3);
  border-radius: 50px;
  border: 2px solid ${dark_orange};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  animation: hazard-animation 2s linear infinite;
  &:hover {
    cursor: pointer;
    opacity: .7;
  }
  @keyframes hazard-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(242,87,0, 0.7);
    }
    40% {
      box-shadow: 0 0 0 15px rgba(242,87,0, 0);
    }
    80% {
      box-shadow: 0 0 0 15px rgba(242,87,0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(242,87,0, 0);
    }
  }
  
`;

const CircularIcon = styled.img`
  margin-top: -2px;
  height: 17px;
  width: 17px;
`;

const HazardButton = () => {
    return (
      <CircularHazard>{
            <CircularIcon src={Hazard} />
      }</CircularHazard>
    );
};

export default HazardButton;