import React from 'react';
import Chart from 'react-apexcharts';

const SolarProductivityBarChart = ({ monthlyData }) => {
    const series = [{
        name: "Solar Productivity",
        data: monthlyData
      }]

    const categories = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
        },
        grid: {
            borderColor: "rgba(0,0,0,0.05)",
            padding: {
                right: 30 // Add some right padding to ensure the last label is visible
            }
        },
        legend: {
            offsetY: 2,
            show: false,
            showForSingleSeries: true,
            position: 'bottom',
            itemMargin: {
                horizontal: 12,
            },
            fontSize: '13px',
            fontFamily: 'Inter',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: 16,
                borderRadius: 8, // Adjust this value to round the corners of the bars
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'all'
            },
        },
        colors: ["#3958ff","#912ED3","#58dbbd","#3a0ca3","#f72585","#d74836","#909090"],
        xaxis: {
            categories: categories,
        },
        yaxis: [
            {
              tickAmount: 3,
              min: 0,
              forceNiceScale: true,
            }
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical', // You can also use 'horizontal'
                shadeIntensity: 0.25,
                gradientToColors: undefined, // Optional: specify colors here
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 100], // Define gradient stop points
            },
        },
    };

    return (
        <div style={{marginTop: 16}}>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="720px"
                height="240px"
            />
        </div>
    );
};

export default SolarProductivityBarChart