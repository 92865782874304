import styled from "styled-components";

export const Container = styled.div`
  width: ${(props) => props.width};
  margin: 48px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: right;
`;

export const NavContainer = styled.div`
  display: flex;
`;


export const PageContainer = styled.div`
  width: Calc(100vw - 220px);
  min-width: 974px;
  background-color: #fdfdfd;
`;


export const TabsContainer = styled.div`
  margin-top: 8px;
`;

export const HeaderTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

export const HeaderSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;