import React from "react";
import styled from "styled-components";
import { white } from "../../utils/colors";

const Container = styled.div`
  padding: 24px;
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
    0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  height: max-content;
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: ${white};
  border-radius: 5px;
  box-sizing: border-box;
`;

const Card = ({ width, children }) => {
  return <Container width={width}>{children}</Container>;
};

export default Card;
