const { parse, format, addMonths, differenceInDays, differenceInMonths, min, max, startOfMonth, endOfMonth } = require('date-fns');

const allKeys = [
    'unit',
    'serviceClass',
    'utility',
    'serviceAddress',
    'tariff',
    'customer',
    'accountNumber'
  ]

const monthlyKeys = [
    'meterNumbers',
    'totalCharges',
    'totalUsage',
    'energySupplyCharges',
    'usage',
    'demand',
    'totalEnergyCharges',
    'totalSupplyCharges',
    'totalDeliveryCharges',
    'energyDeliveryCharges',
    'demandDeliveryCharges',
    'demandSupplyCharges',
    'totalDemandCharges',
    'totalEnergyChargesWithTax',
    'totalDemandChargesWithTax',
  ]

const getMonthsBetweenDates = (startDateString, endDateString) => {
    try {
        const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
        const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());

        const diffMonths = differenceInMonths(endDate, startDate);
        const monthsArray = [];

        for (let i = 0; i <= diffMonths; i++) {
            const currentDate = addMonths(startDate, i);
            const monthYearString = format(currentDate, 'yyyy-MM');
            monthsArray.push(monthYearString);
        }

        return monthsArray;
    } catch {
        return []
    }
};

const getDaysBetweenDates = (startDateString, endDateString) => {
    try {
        const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
        const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());

        return differenceInDays(endDate, startDate);
    } catch {
        return 0
    }
};

const getDaysInRangeForMonth = (startDateString, endDateString, monthString) => {
    const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
    const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());
    const monthDate = parse(monthString, 'yyyy-MM', new Date());

    // Start and end dates for the given month
    const monthStartDate = startOfMonth(monthDate);
    const monthEndDate = endOfMonth(monthDate);

    // Find the intersection of the date ranges
    const rangeStartDate = max([startDate, monthStartDate]);
    const rangeEndDate = min([endDate, monthEndDate]);

    // Calculate the number of days in the intersected range
    if (rangeStartDate > rangeEndDate) {
        return 0; // No overlap between the date ranges
    }
    
    return differenceInDays(rangeEndDate, rangeStartDate) + 1;
};

function createDefaultObj(item) {
    var newObj = {}
    
    for (let key of allKeys) {
        newObj[key] = item[key]
    }

    for (let key of monthlyKeys) {
        newObj[key] = null
    }

    if ('meterNumbers' in item) {
        newObj['meterNumbers'] = item.meterNumbers
    }

    return newObj
}

const roundValues = (obj) => {
    const roundedObj = {};

    Object.keys(obj).forEach(key => {
        if (key === 'usage') {
            roundedObj[key] = Math.round(obj[key]); // No decimal points
        } else if (typeof obj[key] === 'number') {
            roundedObj[key] = parseFloat(obj[key].toFixed(2)); // 2 decimal points
        } else {
            roundedObj[key] = obj[key]; // Keep other types unchanged
        }
    });

    return roundedObj;
};


export function convertToMonthlyData(arr) {

    var datesObj = {}

    arr.forEach(item => {

        // check account number entry is created
        if (!(item.accountNumber in datesObj)) {
            datesObj[item.accountNumber] = { [item.serviceClass]: { dates: {}}}
        };

        // check service class entry is created
        if (!(item.serviceClass in datesObj[item.accountNumber])) {
            datesObj[item.accountNumber][item.serviceClass] = { dates: {}}
        };

        var dates = []
        var daysInRange = 1

        // create array of months corresponding to item 
        if ('deliveryDate' in item) {
            dates = [item.deliveryDate.slice(0,7)]
        } else {
            dates = getMonthsBetweenDates(item.startDate, item.endDate)
            daysInRange = getDaysBetweenDates(item.startDate, item.endDate)
        };

        // iterate through dates
        dates.forEach((date) => {
            if (!(date in datesObj[item.accountNumber][item.serviceClass].dates)) {
                datesObj[item.accountNumber][item.serviceClass].dates[date] = createDefaultObj(item)
            }
            
            // get number of days from the bill that are in this month
            var daysInMonth = 'deliveryDate' in item ? 1 : getDaysInRangeForMonth(item.startDate, item.endDate, date)
            
            // for each key, if key in item calculate average, multiply by number of days, and add to obj
            monthlyKeys.forEach((key) => {
                if (key === 'demand') {
                    return
                }

                if (key === 'meterNumbers' && 'meterNumbers' in item) {
                    datesObj[item.accountNumber][item.serviceClass].dates[date].meterNumbers = datesObj[item.accountNumber][item.serviceClass].dates[date].meterNumbers.concat(item.meterNumbers)
                }

                if (key in item && key !== 'meterNumbers') {
                    datesObj[item.accountNumber][item.serviceClass].dates[date][key] += (item[key] / daysInRange) * daysInMonth
                }
            });

            if (item.serviceClass === 'electric') {
                if ("demand" in item) {
                    if (datesObj[item.accountNumber][item.serviceClass].dates[date].demand < item.demand) {
                        datesObj[item.accountNumber][item.serviceClass].dates[date].demand = item.demand
                    }
                }
            }

            datesObj[item.accountNumber][item.serviceClass].dates[date].month = date
        });
    });

    const cleanedArr = []

    for (let accNum in datesObj) {
        for (let sc in datesObj[accNum]) {
            for (let date in datesObj[accNum][sc].dates) {
                cleanedArr.push(datesObj[accNum][sc].dates[date])
            }
        }
    }

    return cleanedArr.map((item, index) => {
        let roundedObj = roundValues(item);
        roundedObj.id = index;
        if (roundedObj.totalEnergyChargesWithTax) {
            roundedObj.blendedRate = parseFloat((item.totalEnergyChargesWithTax / item.usage).toFixed(4));
        }
        if (item.meterNumbers) {
            var uniqueMeterNums = new Set(item.meterNumbers)
            roundedObj.meterNumbers = [...uniqueMeterNums]
        }
        return roundedObj;
    });
}