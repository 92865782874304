import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../../../components/Modal";
import Logo from "../../../../assets/BlackLogo.svg"
import { SquareButton } from "../../../../components/buttons/SquareButton";
import { requestAccess } from "./requestAccess";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../../../components/snackbars/MessageBar";
import { useAuth } from "../../../../store/AuthContext";

const StyledBox = styled.div`
  width: 560px;

  & .group {
    left: 0;
    top: 0;
    width: 560px;

    & .text-wrapper {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      left: 105px;
      letter-spacing: 0;
      line-height: 39px;
      text-align: center;
      width: 560px;
      margin-bottom: 0px;
    }

    & .image {
      height: 305px;
      object-fit: cover;
      width: 458px;
      margin-left: 51px;
    }

    & .logo {
      height: 15px;
      left: 219px;
      top: -1px;
      width: 110px;
      margin-left: 225px;
    }

    & .congratulations-on {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      left: 0;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      width: 547px;
      margin-top: 16px;
      margin-bottom: 26px;
    }

    & .buttonContainer {
        padding-left: 200px;
        margin-bottom: 8px;
    }

    & .overlap-group {
      background-color: #f3f6ff;
      border: 1px solid;
      border-color: #3958ff;
      border-radius: 5px;
      height: 31px;
      left: 198px;
      width: 152px;

      & .div {
        color: #3958ff;
        font-size: 12px;
        font-weight: 500;
        left: 35px;
        letter-spacing: 0;
        line-height: normal;
        top: 7px;
      }
    
    }
  }
`;

const RequestAccessModal = ({ open, handleClose }) => {
    
    const { currentUser } = useAuth();
    const [reachedOut, setReachedOut] = useState(false);
    const handleReachedOut = () => { setReachedOut(true) }
    const [isLoading, setIsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleRequestAccess = async () => {
        setIsLoading(true);
        try {
            await requestAccess(await currentUser.getIdToken());
            handleReachedOut()
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
        } catch (error) {
          enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
          setIsLoading(false);
          console.log("error uploading bills", error);
        }
      };
 
    const modalContent = () => {
        return (
            <StyledBox>
            <div className="group">
                <img className="logo" alt="Logo" src={Logo} />
                {
                    reachedOut ?
                    <div>
                        <p className="text-wrapper">{reachedOut ? 'Success!' : 'Unlock Unlimited Insights with Premium'}</p>
                        <div style={{height: '24px', width: '100%'}}></div>
                        <p className="congratulations-on">Thank you for requesting access! Someone from the Upward team will be in touch with you shortly.</p>
                    </div> :
                    <div>
                        <p className="text-wrapper">Get API Access</p>
                        <p className="congratulations-on">Click below to request access to the API and get started with Upward.</p>
                        <div 
                        className="buttonContainer"
                        >
                          <div style={{width: 160}} onClick={() => handleRequestAccess()} >
                            <SquareButton isloading={isLoading} text='Get Access' width="160px"/>
                          </div>
                        </div>
                    </div>
                }
            </div>
        </StyledBox>
        );
    };

    return (
        <Modal
        modalContent={modalContent()}
        open={open}
        handleClose={handleClose}
        />
    );
};

export default RequestAccessModal;