import React from "react";
import Modal from "../../../components/Modal";
import styled from "styled-components";
import Interval from "../../../assets/Interval.svg";
import DownloadBills from "../../../assets/DownloadBills.svg";
import HorizontalCard from "./HorizontalCard";
import Star from "../../../assets/Star.svg";
// import { downloadBillsAsCSV } from "../apiCalls/downloadBillsAsCSV";
import { downloadEnergyBills } from "../apiCalls/downloadEnergyBills";
import { enqueueSnackbar } from "notistack";
import { downloadFile } from "../../../utils/downloadFile";
import { isNil } from "lodash";
import { downloadBillsDataEnergyStarFormat } from "../apiCalls/downloadBillsDataEnergyStarFormat";
import { buttons, snackbarStyles } from "../../../components/snackbars/MessageBar";
import { useAuth } from "../../../store/AuthContext";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 300px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: -24px;
`;

const DownloadMeterData = ({ open, handleClose, data, downloadTableData }) => {
    const { currentUser } = useAuth();

    // const handleDownloadCSV = async () => {
    //     try {
    //         const download = await downloadBillsAsCSV({
    //             json:
    //                 !isNil(data)
    //                 ? JSON.stringify( data.billsData.data )
    //                 : null,
    //             idToken: await currentUser.getIdToken(),
    //         });
            
    //         downloadFile(
    //             download.data,
    //             data.customer,
    //             "csv"
    //         );
    //         enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
        
    //     } catch (error) {
    //         enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
    //         console.log("error downloading data as csv", error);
    //     }
    // };

    const handleDownloadEnergyStar = async () => {
        try {
            const download = await downloadBillsDataEnergyStarFormat({
                json:
                    !isNil(data)
                    ? JSON.stringify( data.billsData.data )
                    : null,
                idToken: await currentUser.getIdToken(),
            });
            
            downloadFile(
                download.data,
                data.customer,
                "zip"
            );
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
        
        } catch (error) {
            enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
            console.log("error downloading data as csv", error);
        }
    };

    const handleDownloadPDFs = async () => {
        try {
            const download = await downloadEnergyBills({
                idToken: await currentUser.getIdToken(),
                files: data.fileNames,
            });
    
            downloadFile(
                download.data,
                data.customer,
                "zip"
            );
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success })
        } catch (error) {
            enqueueSnackbar(buttons.error, { style: snackbarStyles.error })
            console.log("error downloading pdfs", error);
        }
    }; 
  
    const modalContent = () => {
        return (
            <Container>
                <Title>Download Meter Data</Title>
                <HorizontalCard
                    imgSrc={Interval}
                    title={"Table Data"}
                    description={"Download meter table data as CSV."}
                    active={false}
                    onClick={downloadTableData}
                />
                <HorizontalCard
                    imgSrc={Star}
                    title={"Energy Star Format"}
                    description={"Get data formatted for Energy Star."}
                    active={false}
                    onClick={handleDownloadEnergyStar}
                />
                <HorizontalCard
                    imgSrc={DownloadBills}
                    title={"Bill PDFs"}
                    description={"Download Bill PDFs."}
                    active={false}
                    onClick={handleDownloadPDFs}
                />
        </Container>
        );
    };
    return (
        <Modal
        modalContent={modalContent()}
        open={open}
        handleClose={handleClose}
        />
    );
};

export default DownloadMeterData;