import React from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import { SquareButton } from "../../../components/buttons/SquareButton";

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessText = styled.div`
  margin-top: -16px;
  font-size: 26px;
  font-weight: 500;
`;

const Text = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 26px;
`;

const SaveModal = ({ open, handleClose, saveFunction, data, resetData }) => {

  const modalContent = () => {
    return (
      <Container>
        <SuccessText>Save Data</SuccessText>
        <Text>
          Are you sure you want to save your changes? This action is
          irreversable.
        </Text>
        
        <div style={{display: "flex"}}>
        <div
          onClick={async () => {
            resetData()
            handleClose();
          }}
        >
            <SquareButton
              text="Discard Changes"
              width="140px"
              bordercolor={"#ED3939"}
              backgroundcolor={"#FDECEC"}
              hovercolor={"#FAC7C7"}
            />
        </div>
        <div 
          style={{marginLeft: "8px"}}
          onClick={async () => {
            saveFunction(data);
            handleClose();
          }}
          >
            <SquareButton
              text="Save Changes"
              width="140px"
            />
        </div>
        </div>
      </Container>
    );
  };

  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default SaveModal;
